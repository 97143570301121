.home-content .row {
  padding: 0 0;
  margin: 0;
}

.dropdown p {
  font-family: "Paragraph";
  padding: 0 0 4px 0px;
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.25;
  color: var(--inchcape-label);
}

.dropdown-seats .k-input-values {
  display: flex;
  align-items: center;
  font-family: "Paragraph";
}

.dropdown-seats .k-chip-actions {
  display: none !important;
}

.dropdown-seats .k-chip {
  padding: 4px 9px;
}

.dropdown-seats .k-chip-content {
  margin-inline-start: 0;
}

.dropdown-seats .k-chip-list-md {
  gap: 2px;
}

/* Date Range picker */

.date-range-picker .k-daterangepicker {
  gap: 44px;
}

.home-content .k-floating-label-container,
.home-content .k-dateinput {
  width: 100%;
}

.book-daterangepicker > label {
  width: 50%;
}

.book-daterangepicker .k-calendar .k-nav-today,
.k-calendar .k-calendar-nav-today {
  cursor: not-allowed;
  pointer-events: none;
}

/* Daylong Styling */
.hour-daylong .k-checkbox {
  border-radius: 50%;
  border-color: var(--inchcape-blue);
}

.hour-daylong .k-checkbox:checked {
  color: var(--inchcape-white);
  border: none;
  background-color: var(--inchcape-white);
  background: url(../../img/radio-check.png) no-repeat center center/cover;
  box-shadow: 0 0 0 2px var(--inchcape-sidebar-hover) !important;
}

.hour-daylong .k-checkbox-label {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: var(--inchcape-label);
  margin-inline-start: 9px;
}

/* Hour Styling */
.hour-time {
  padding-left: 0 !important;
}
