.homepage {
  padding: 3rem min(6rem, 5%);
  display: flex;
  flex-direction: column;
  gap: 31px;
  width: 100%;
}

.home-module {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 767px) {
  .homepage {
    width: 100%;
    padding: 2rem 1rem;
    margin-left: 0;
  }

  .home-mobile-header svg {
    width: 25px;
    height: 25px;
    margin-right: 25px;
  }

  .home-mobile-header h1 {
    margin: 0;
    font-family: "Title";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
  }
}
