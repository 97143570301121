.admin-cont {
  display: flex;
  flex-direction: column;
  padding: 3rem min(6rem, 5%);
  width: 100%;
}

.admin {
  width: 100%;
  border: 1px solid #edf1f3;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px 46px;
  gap: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 768px) {
  .admin-cont {
    padding: 2rem 1rem;
    margin-left: 0;
  }

  .admin {
    width: 100%;
    padding: 1.2rem;
  }

  .admin-mobile-header {
    margin-bottom: 31px;
  }

  .admin-mobile-header svg {
    width: 25px;
    height: 25px;
    margin-right: 25px;
  }

  .admin-mobile-header h1 {
    margin: 0;
    font-family: "Title";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
  }
}
