.approval-nav {
    width: 100%;
  }
  
  .approval-nav .nav-item {
    border: 2px solid var(--inchcape-blue);
  }
  
  .approval-nav a.nav-link {
    color: var(--inchcape-blue);
    font-family: "Paragraph";
    font-size: max(0.9vw, 16px);
    height: 100%;
  }
  
  .approval-nav a.nav-link.active {
    color: #ffffff !important;
    font-weight: 700;
    background: var(--inchcape-blue);
  }
  
  ul.approval-nav.nav.nav-fill {
    width: 100%;
  }
  
  .nav-link {
    cursor: pointer;
  }
  