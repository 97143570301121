.home-booking-box {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: var(--inchcape-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Header */
.home-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--inchcape-dark);
  margin-bottom: 46px;
}

.home-header h3 {
  font-family: "Title";
  font-size: 20px;
  font-weight: 700;
  color: var(--inchcape-dark);
  margin-bottom: 0;
}

.floor-layout-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  text-align: center;
  background-color: var(--inchcape-dark);
  color: var(--inchcape-white);
  padding: 5px 16px;
  border-radius: 4px 4px 0px 0px;
  border: none;
}

.floor-layout-btn span {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  font-family: "Paragraph";
}

.floor-layout-btn svg {
  width: 1.3rem;
  height: 1.3rem;
}
/* Modal Styling */
.modal-style {
  width: 55vw;
  max-width: 70vw;
}

.office-layout {
  width: 53vw;
  height: auto;
}

.modal-content {
  border-radius: 0;
  background-color: #f5f5f4;
}

.modal-header {
  border-bottom: none;
  padding: 15px 15px 0 0;
}

.modal-header .btn-close {
  padding: 0;
}

.btn-close {
  background: url(../../img/close-icon.png) no-repeat center center/cover !important;
  width: 2em;
  height: 2em;
  border-radius: 50px;
  padding: 7px;
  opacity: 10;
}
.no-layout {
  height: 50vh;
  display: flex;
  font-family: "Paragraph";
  justify-content: center;
  align-items: center;
  color: var(--inchcape-label);
}

/* Booking Btn */
.booking-btn {
  justify-self: end;
  align-self: flex-end;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: var(--inchcape-blue);
  display: inline-block;
  padding: 0 23px;
}

.booking-btn:hover {
  color: var(--inchcape-white);
  background: #049ec9;
  transition: all 0.3s ease-in-out;
}

.booking-btn:disabled {
  cursor: default;
  background-color: var(--inchcape-blue);
  color: var(--inchcape-blue);
  opacity: 0.7;
}

.booking-btn span {
  font-family: "Title";
  font-weight: 700;
  font-size: 16px;
  line-height: 38px;
  text-align: center;
  color: var(--inchcape-white);
}

.home-booking-box {
  display: grid;
  grid-template-rows: 1fr;
}

.home-box {
  grid-area: 2/ -1 / 3/ -1 !important;
}

.home-booking-box .overlay {
  grid-area: 1 / 1 / 5 / 2;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.77);
  user-select: none;
}

.home-booking-box .overlay .k-loader,
.home-booking-box .overlay p {
  color: var(--inchcape-blue);
  font-size: 24px;
  font-weight: 700;
  font-family: "Paragraph";
}

.err-message p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  font-family: "Paragraph";
  color: var(--inchcape-red);
  text-align: center;
  margin-top: 22px;
}

@media screen and (max-width: 769px) {
  .booking-btn {
    width: 100%;
  }

  .home-header h3 {
    font-size: 16px;
    line-height: 1.4;
  }

  .floor-layout-btn {
    padding: 4.5px 9.5px;
    gap: 10px;
  }

  .floor-layout-btn span {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  .floor-layout-btn svg {
    width: 0.95rem;
  }

  .modal.show .modal-dialog {
    transform: none;
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 95vw;
  }

  .modal-content {
    border-radius: 0;
    background-color: #f5f5f4;
    max-height: 90vh;
  }

  .modal-body {
    overflow: auto;
    margin: auto;
  }

  .office-layout {
    width: 250%;
    height: auto;
  }

  .err-message p {
    padding: 0 10px;
  }
}
