.home-content .row {
  padding: 0 0;
  margin: 0;
}

.dropdown p {
  font-family: "Paragraph";
  padding: 0 0 4px 0px;
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.25;
  color: var(--inchcape-label);
}
.dropdown .RoomN {
  font-family: "Paragraph";
  padding: 0 0 4px 0px;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.25;
  color: var(--inchcape-blue);
}
.dropdown-seats .k-input-values {
  display: flex;
  align-items: center;
}

.attendeeTooltip {
  margin:0;
  padding:0 0 0 15px;
}

/* Date Range picker */

.date-range-picker .k-daterangepicker {
  gap: 44px;
}

.home-content .k-floating-label-container,
.home-content .k-dateinput {
  width: 100%;
}

.book-daterangepicker > label {
  width: 50%;
}

/* Daylong Styling */
.hour-daylong .k-checkbox {
  border-radius: 50%;
  border-color: var(--inchcape-blue);
}

.hour-daylong .k-checkbox:checked {
  color: var(--inchcape-white);
  border: none;
  background-color: var(--inchcape-white);
  background: url(../../img/radio-check.png) no-repeat center center/cover;
  box-shadow: 0 0 0 2px var(--inchcape-sidebar-hover) !important;
}

.hour-daylong .k-checkbox-label {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: var(--inchcape-label);
  margin-inline-start: 9px;
}
/* Default width for larger screens */
.hour-daylong .k-tooltip-content {
  width: 350px;
}
/* Media query for screens with a maximum width of 768px */
@media screen and (max-width: 768px) {
  .hour-daylong .k-tooltip-content {
    width: 250px; /* Adjust the width for smaller screens */
  }
}

/* Media query for screens with a maximum width of 576px */
@media screen and (max-width: 576px) {
  .hour-daylong .k-tooltip-content {
    width: 200px; /* Adjust the width for even smaller screens */
  }
}

/* Tool tip Header */
.Meetroom .k-tooltip-content {
  font-size: 12px;
  width: 250px;
  font-family: "Paragraph";
}
.Meetpod .k-tooltip-content {
font-size: 12px;
width: 100px;
font-family: "Paragraph";
}

/* Hour Styling */
.hour-time {
  padding-left: 0 !important;
}

.textbox p,
.textbox-reason p {
  font-family: "Paragraph";
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: var(--inchcape-dark);
  padding: 0 6px 0 3px;
}

.booking-btn:disabled {
  cursor: default;
  background-color: var(--inchcape-blue);
  color: var(--inchcape-blue);
  opacity: 0.7;
}
.k-tooltip-content{
  font-size: 12px;
  font-family: "Paragraph";
}
@media screen and (max-width: 767px) {
  .k-tooltip-content{
    font-size: 11px;
    font-family: "Paragraph";
  }
}
