.report {
  display: flex;
  flex-direction: column;
  padding: 3rem min(6rem, 5%);
  gap: 50px;
  width: 100%;
}

.reports-box {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: var(--inchcape-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 34px 48px;
}

.reports-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--inchcape-dark);
  margin-bottom: 46px;
}

.reports-title h3 {
  font-family: "Title";
  font-size: 20px;
  font-weight: 700;
  line-height: 1.6;
  color: var(--inchcape-dark);
  margin-bottom: 0;
}

.report-module {
  display: flex;
  flex-direction: row;
  /* gap: 43px; */
}

/* Report Logo */
.report-icon {
  display: flex;
  align-items: center;
  gap: 24.75px;
}

.report-title {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 20px;
  color: #00233a;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .report {
    padding: 2rem 1rem;
    gap: 31px;
  }

  .report-mobile-header svg {
    width: 25px;
    height: 25px;
    margin-right: 25px;
  }

  .report-mobile-header h1 {
    margin: 0;
    font-family: "Title";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
  }
}
