.dialog-container1 {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.dialog-title1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 37px 54px 0px 54px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: "Paragraph";
  color: #424242;
}

.dialog-footer1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-btn {
  display: flex;
  border: none;
  padding: 5px 55px;
}

.btn-no1 {
  margin-right: 8px;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.btn-no1:hover {
  color: #424242;
  background: #e4e4e4;
  transition: all 0.3s ease-in-out;
}

.btn-yes1 {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  border: 1px solid #16bae7;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.04) 99.48%,
      rgba(0, 0, 0, 0.04) 99.49%
    ),
    #16bae7;
  border-radius: 4px;
}

.btn-yes1:hover {
  color: var(--inchcape-white);
  background: #049ec9;
  transition: all 0.3s ease-in-out;
}

.dialog-container1 {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.dialog-title1 {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 37px 54px 0px 54px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #424242;
}

.dialog-footer1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-btn {
  display: flex;
  border: none;
  padding: 5px 55px;
}
