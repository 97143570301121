.reports-content > * {
  border: 1px solid red;
}

.reports-content .row {
  padding: 0.5rem 0;
  margin: 0;
}

.dropdown label {
  padding: 0 0 4px 8px;
  margin: 0;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: var(--inchcape-label);
}

.StartDate,
.EndDate {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.dateRange {
  display: flex;
  gap: 0;
  justify-content: space-between;
  /* flex-direction: column !important; */
}
.dateRange label {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 0.5rem !important;
}
.dateRange .k-dateinput {
  width: 100%;
}
.d > label {
  padding: 12px;
  width: 50%;
}

.reports-content .k-floating-label-container,
.reports-content .k-dateinput {
  width: 100%;
}

/* Reports Buttons Styling */
.reports-btn {
  border: none;
  border-radius: 4px;
  padding: 0;
  display: inline-block;
}
.reports-content .StartDate {
  width: 68px;
  height: 20px;
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  /* $component-text */
  color: #424242;
  /* Inside auto layout */
}
.reports-content .EndDate {
  width: 68px;
  height: 20px;
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  /* $component-text */
  color: #424242;
  /* Inside auto layout */
}
.reports-btn span {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.download {
  background: var(--inchcape-blue);
  color: var(--inchcape-white);
}

.download {
  padding: 5px 27px;
  border: 1px solid var(--inchcape-blue);
}

.download:hover {
  color: var(--inchcape-white);
  background: #049ec9;
  transition: all 0.3s ease-in-out;
}

.download:disabled {
  opacity: 0.7;
  pointer-events: none;
}

.clear {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 2e-6) 0%,
      rgba(0, 0, 0, 0.02) 100%
    ),
    #f5f5f5;
  padding: 5px 42px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.clear:hover {
  color: #424242;
  background: #e4e4e4;
  transition: all 0.3s ease-in-out;
}

.send-email {
  background: var(--inchcape-blue);
  color: var(--inchcape-white);
  text-align: left;
  margin-left: 0.5rem;
}

.send-email {
  padding: 5px 27px;
  border: 1px solid var(--inchcape-blue);
}

.send-email:hover {
  color: var(--inchcape-white);
  background: #049ec9;
  transition: all 0.3s ease-in-out;
}

.send-email:disabled {
  opacity: 0.7;
  pointer-events: none;
}

h6,
.h6 {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #e40521;
  text-align: center;
  font-family: "Paragraph";
}
h4,
.h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #16bae7;
  text-align: center;
}

.report-module .k-notification-success {
  display: flex;
  gap: 4px;
  height: 27px;
  position: fixed;
  top: 5vw;
  right: 5vw;
  font-family: "Sub Title";
  border-color: #37b400;
  color: white;
  background-color: #37b400;
}
.k-loader {
  color: #16bae7;
  font-size: 20px;
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-family: "Sub Title";
}

@media (min-width: 992px) {
  .col-lg-2 {
    width: 20.2222%;
  }

  .col-lg-4 {
    width: 37.3333%;
  }
}

@media screen and (max-width: 768px) {
  .reports-content {
    padding: 2rem 1rem;
    height: auto;
  }

  .reports-content,
  .reports-content .search-title,
  .reports-content .search-items,
  .reports-content .k-dropdownlist,
  .reports-content .k-daterangepicker,
  .reports-content .k-floating-label-container,
  .reports-content .k-dateinput,
  .reports-content .search-bottom {
    width: 100% !important;
  }

  .reports-content .search-items {
    flex-direction: column;
    gap: 25px;
  }

  .reports-content .search-dropdowns {
    flex-direction: row;
    gap: 25px;
  }

  .reports-content .search-dropdowns > div {
    display: flex;
    flex-direction: column;
  }

  .reports-content .search-dropdowns label {
    height: auto;
  }

  .reports-content .k-daterangepicker {
    align-items: flex-start;
    flex-direction: column;
    gap: 25px;
  }

  .reports-content .search-bottom {
    justify-content: center;
  }

  .reports-content .search-bottom button {
    padding: 5px 10%;
  }
}
