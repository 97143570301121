.book-type-title span {
  font-family: "Title";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--inchcape-blue);
}

/* Radio Button */
.radio-btn {
  display: flex;
  padding-right: 0 !important;
  height: 24px;
}

.radio-btn .k-radio-label {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: var(--inchcape-label);
  margin-right: 5px;
}

.radio-btn .k-radio {
  border-color: var(--inchcape-blue);
}

.radio-btn .k-radio:checked {
  background: url(../../img/radio-check.png) no-repeat center center/cover;
  border: none;
}

.radio-btn .k-radio:focus {
  box-shadow: 0 0 0 2px var(--inchcape-sidebar-hover);
}

.change-color {
  width: 12px;
  height: 12px;
  border-radius: 50px;
}

.info-btn {
  cursor: pointer;
  width: 0.85em;
  height: auto;
  color: var(--inchcape-purple);
  border: 1px solid var(--inchcape-purple);
  border-radius: 50px;
  vertical-align: baseline;
}

.info-btn:hover {
  color: var(--inchcape-white);
  background-color: var(--inchcape-purple);
}
