.office-dialog .k-dialog {
  width: min(90vw, 950px);
  padding: 0;
  max-height: 92vh;
}

.co-dialog-offices {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  padding: 5px 27px 20px 27px;
  overflow-y: auto;
  height: auto;
}

.co-dialog-offices .main-cont {
  grid-area: 1 / 1 / -2 / -2;
}

.co-dialog-offices .overlay {
  grid-area: 1 / 1 / -2 / -2;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.77);
  user-select: none;
}

.co-dialog-offices .overlay .k-loader,
.co-dialog-offices .overlay p {
  color: var(--inchcape-blue);
  font-size: 24px;
  font-weight: 700;
  font-family: "Paragraph";
}

.co-dialog-offices .overlay .k-loader-pulsing-2 {
  transform: scale(1.2);
}

.co-dialog-offices .areas .k-loader-pulsing-2 {
  transform: scale(1.6);
}

.co-dialog-offices h1 {
  text-align: center;
  padding: 20px 0;
  font-family: "Title";
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 25px;
  color: var(--inchcape-blue);
}

.form-content div.k-form-field {
  margin-top: 0;
}

.form-content label.k-label {
  padding-left: 4.6%;
  font-family: "Paragraph";
  font-size: 12px;
  line-height: 20px;
  color: #666666;
}

.form-content input.k-input::placeholder {
  font-family: "Paragraph";
  font-size: 14px;
  line-height: 20px;
  color: #666666;
}

.co-dialog-offices .form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  width: 100%;
}

.form-content input.k-input {
  font-family: "Paragraph";
  font-size: 14px;
  line-height: 20px;
  color: var(--inchcape-label);
  padding: 6px 4px 5px 9px;
  height: 30px;
}

.form-content .basic-details span.k-dropdownlist {
  color: var(--inchcape-label);
  padding: 6px 4px 5px 9px;
  height: 30px;
}

.form-content .basic-details span.k-input-inner {
  padding: 0;
}

.thumbnail-input {
  padding: 0 12px;
}

.form-content .thumbnail-upload {
  display: flex;
  align-items: center;
  padding: 6px 6px 5px 8px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 2e-6) 0%,
      rgba(0, 0, 0, 0.02) 100%
    ),
    #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.form-content .thumbnail-upload > p {
  text-overflow: ellipsis;
  padding-right: 10px;
  overflow: hidden;
  font-family: "Paragraph";
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  text-align: start;
  color: var(--inchcape-label);
  width: 93%;
}

.form-content .layout-upload {
  border: 1px dashed var(--inchcape-blue);
  fill: none;
  height: min(100%, 200px);
  padding: 0;
  background: white;
}

.form-content .layout-upload > div {
  margin: 0;
  padding: 42px 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: min(100%, 200px);
  background: transparent;
}

.form-content .layout-upload p {
  font-family: "Paragraph";
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--inchcape-blue);
  padding: 0;
}

.form-content .layout-upload svg {
  color: var(--inchcape-blue);
  width: 16px;
}

.form-content .area-capacity div > h4 {
  padding: 0;
  margin: 0;
  font-family: "Paragraph";
  font-weight: 700;
  font-size: min(1.8vw, 16px);
  line-height: 20px;
  text-align: start;
  color: var(--inchcape-blue);
}

.form-content .areas button > svg {
  width: 14px;
  height: 14px;
  color: var(--inchcape-blue);
}

.area-capacity .area-title button > svg {
  color: var(--inchcape-blue);
  width: 20px;
  height: 20px;
}

.area-capacity .area-title {
  display: flex;
  gap: 15px;
  align-items: center;
}

.area-capacity .area-div {
  display: flex;
  flex-direction: column;
}

.area-capacity .area-title > div {
  display: flex;
  gap: 7px;
}

.area-capacity .area-div button {
  border: none;
  padding: 0;
  background: none;
}

.form-content .area-capacity .area-inputs {
  display: flex;
  flex-direction: column;
  gap: 50px;
  height: 100%;
}

.form-content .hours {
  width: 100%;
  border-top: 0.5px solid #8f8f8f;
  padding-top: 50px;
  padding-bottom: 10px;
  margin-bottom: 35px;
}

.form-content .hours div.k-timepicker {
  height: 30px;
  border: 1px solid rgba(66, 66, 66, 0.5);
}

.form-content .hours span.k-dateinput {
  padding: 5px 8px;
  font-family: "Paragraph";
  font-size: 14px;
  color: var(--inchcape-label);
  line-height: 20px;
}

.form-content .hours > p {
  margin: 0;
  padding: 0;
  font-family: "Paragraph";
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--inchcape-blue);
}

.co-dialog-offices div.k-form-buttons {
  display: flex;
  justify-content: center;
  margin-top: 4px;
}

.co-dialog-offices div.k-form-buttons > button {
  box-sizing: border-box;
  border-radius: 4px;
  height: 30px;
  width: 128px;
  padding: 5px 9px;
  font-family: "Paragraph";
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.co-dialog-offices div.k-form-buttons > button:first-child {
  color: var(--inchcape-label);
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 2e-6) 0%,
      rgba(0, 0, 0, 0.02) 100%
    ),
    #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.co-dialog-offices div.k-form-buttons > button:first-child:hover {
  color: #424242;
  background: #e4e4e4;
  transition: all 0.3s ease-in-out;
}

.co-dialog-offices div.k-form-buttons > button:nth-child(2) {
  color: #fff;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.04) 99.48%,
      rgba(0, 0, 0, 0.04) 99.49%
    ),
    var(--inchcape-blue);
  border: 1px solid var(--inchcape-blue);
}

.co-dialog-offices div.k-form-buttons > button:nth-child(2):hover {
  color: var(--inchcape-white);
  background: #049ec9;
  transition: all 0.3s ease-in-out;
}

#uploadbtn,
#layoutbtn {
  display: none;
}

.co-dialog-offices .errorMsg {
  font-family: "Paragraph";
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #e40521;
}

.co-dialog-offices .areas {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.co-dialog-offices .areas h2 {
  font-family: "Title";
  font-size: 24px;
  color: var(--inchcape-blue);
  margin: 0;
}

.co-dialog-offices .areas > .title {
  display: flex;
}

.co-dialog-offices .areas .title > .main-area {
  display: flex;
  gap: 10px;
  margin: 0 auto;
  align-items: center;
}

.co-dialog-offices .areas .back {
  width: 25px;
  height: 25px;
  color: var(--inchcape-blue);
  cursor: pointer;
}

.co-dialog-offices .areas .back:hover {
  color: #ffffff;
  border: 1px solid var(--inchcape-blue);
  border-radius: 50%;
  background: var(--inchcape-blue);
}

.co-dialog-offices .areas .info {
  width: 16px;
  height: 16px;
  color: #9e61a4;
  border: 1px solid #9e61a4;
  border-radius: 50%;
  cursor: pointer;
}

.co-dialog-offices .areas .info:hover {
  border: #9e61a4;
  color: #ffffff;
  background: #9e61a4;
}

.co-dialog-offices .areas > button {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  gap: 5px;
  width: auto;
  height: 30px;
  border: 1px solid var(--inchcape-blue);
  background: #ffffff;
  border: 1px solid var(--inchcape-blue);
  border-radius: 4px;
  padding: 15px 8px;
  margin-top: -10px;
}

.co-dialog-offices .areas button > p {
  padding: 0;
  font-family: "Paragraph";
  font-size: 14px;
  color: var(--inchcape-blue);
}

.co-dialog-offices .save-loader {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  color: var(--inchcape-blue);
  font-family: "Paragraph";
  font-size: 16px;
}

.areas-table {
  position: relative;
}

.co-dialog-offices .areas div.k-notification-success,
.co-dialog-offices .areas div.k-notification-error {
  display: flex;
  gap: 4px;
  height: 27px;
}

.co-dialog-offices .areas .k-animation-container {
  position: absolute;
  top: 3rem;
  left: 0;
  right: 0;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .co-dialog-offices {
    padding: 0;
  }

  .co-dialog-offices > div {
    display: flex;
    min-width: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .form-content .thumbnail-upload > p {
    width: 95%;
  }

  .form-content .area-input .k-form-field {
    display: flex;
    flex-direction: column;
  }

  .co-dialog-offices .mobile-table .line {
    column-gap: 8px;
  }

  .form-content > div:first-child > div > div:first-child {
    height: auto;
  }

  .co-dialog-offices .hours {
    align-items: flex-start;
  }

  .co-dialog-offices .hours > div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .co-dialog-offices div.k-scrollview {
    width: 100%;
    padding-left: 0;
    height: 300px;
    border: none;
  }

  .form-content .area-capacity .area-div {
    width: 100%;
    height: 90%;
    overflow-y: auto;
    padding-right: 10px;
    padding-left: 12px;
    padding-bottom: 20px;
  }

  .form-content .area-input {
    padding: 0 0.2rem;
  }

  .form-content .k-scrollview-next,
  .form-content .k-scrollview-prev {
    display: none;
  }

  .co-dialog-offices .seat-range .k-picker-solid {
    width: 100%;
  }

  .form-content .area-capacity div > h4 {
    font-size: 16px;
    white-space: normal;
  }

  .form-content .areas label.k-label {
    white-space: normal;
  }

  .form-content .hours p {
    padding-left: 16px;
  }

  .form-content .area-title {
    margin-bottom: 1rem;
  }

  .co-dialog-offices .k-scrollview-pageable > .k-button.k-primary,
  .co-dialog-offices .k-scrollview-nav > .k-link.k-primary {
    background: var(--inchcape-blue);
    border: var(--inchcape-blue);
  }

  .co-dialog-offices .k-scrollview-pageable,
  .k-scrollview-nav {
    cursor: pointer;
  }
}
