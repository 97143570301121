.topnav {
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  padding: 30px min(4vw, 65px);
  display: flex;
  align-items: center;
  background: #f4f6f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
}

.topnav .container {
  max-width: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

.topnav .brand {
  user-select: none;
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 0;
  padding: 0;
}

.topnav .brand svg {
  display: inline-block;
  width: 28px;
  height: 28px;
  color: #00233a;
  margin: 0;
  padding: 0;
}

.topnav .brand h1 {
  display: inline-block;
  font-family: "Title";
  font-size: max(1.7vw, 24px);
  font-weight: 700;
  color: #00233a;
  margin: 0;
}

.topnav .navbar-nav {
  display: flex;
  gap: 24px;
  align-items: center;
}

.topnav-icon {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
}

.topnav button.dropdown-toggle {
  background: none;
  color: #00233a;
  border: none;
  padding: 0;
}

.topnav .btn-check:checked + .btn,
.topnav :not(.btn-check) + .btn:active,
.topnav .btn:first-child:active,
.topnav .btn.active,
.topnav .btn.show,
.topnav .btn:hover {
  background: none;
}

.topnav button.dropdown-toggle.btn.btn-primary::after {
  display: none;
  color: #16bae7;
}

.topnav div.dropdown-menu.show {
  left: -9rem;
  top: 2.5rem;
  padding: 0;
  border: none;
  background: none;
  width: 100%;
}

.topnav .dropdown-item:hover {
  background: none;
}

.topnav .dropdown-item {
  white-space: normal;
}

.topnav .dropdown-cont {
  background: #ffffff;
  font-family: "Title";
  font-size: 14px;
  color: #39464f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}

.topnav .dropdown-cont.language,
.topnav .dropdown-cont.city {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 1.5rem 1rem;
  text-align: center;
}

.topnav .dropdown-cont.city {
  max-height: 50vh;
  overflow-y: auto;
  width: 180px;
}

.topnav .dropdown-cont.country {
  display: flex;
  width: 300px;
  grid-template-columns: repeat(2, 1fr);
  max-height: 184px;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 10px;
  text-align: center;
  padding: 1.5rem;
}

.topnav .dropdown-cont.country .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.dropdown-cont.country .item > img {
  filter: contrast(70%) brightness(120%) saturate(75%);
}

.dropdown-cont.country .item > p {
  padding: 0;
  width: 100%;
  overflow-wrap: break-word;
  font-family: "Title";
  font-size: 14px;
}

.topnav .dropdown-cont.office {
  transform: translateX(-13rem);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 20px;
  width: 360px;
  max-height: 574px;
  overflow-y: auto;
}

.topnav .dropdown-cont.office .item {
  min-height: 166px;
  border-radius: 10px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0.5rem 1.5rem;
  /* box-shadow: inset 0 140px 20px 0 rgba(217, 217, 217, 0.2),
    inset 0 -60px 100px 5px #00233a; */
}

.dropdown-cont.office .item > h2 {
  font-family: "Title";
  color: #ffffffda;
  font-size: 24px;
  margin: 0;
}

.dropdown-cont.office .item > p {
  font-family: "Sub Title";
  color: #ffffffc0;
  font-size: 12px;
  white-space: initial;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .topnav {
    display: none;
  }
}
