.invalid-login {
  padding: 0;
  width: 100%;
  background: url("../../img/validate-bg.png") no-repeat;
  background-position: center;
  background-size: cover;
}

.invalid-login > div {
  height: 100%;
  color: var(--inchcape-white);
  text-align: center;
}

.invalid-login h1 {
  font-size: min(14vw, 50px);
  font-family: "Title";
  letter-spacing: 0.05em;
  line-height: 38px;
  font-weight: 700;
}

.invalid-login p {
  font-size: min(8vw, 32px);
  font-family: "Paragraph";
}
