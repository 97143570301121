.landing {
  padding: 3.5rem 0 4rem 4rem;
  width: 100%;
  background: url("../../img/inchcape-fb-image.jpg") no-repeat;
  background-position: center;
  background-size: cover;
}

.landing .content {
  height: 100%;
}

.landing img {
  width: max(15%, 250px);
}

.landing .title-btn {
  color: var(--inchcape-white);
  gap: 2vh;
}

.landing .title {
  font-size: max(7vw, 86px);
  color: inherit;
  height: auto;
  line-height: 1.2;
  font-family: "Title";
}

.landing .btn {
  font-size: max(1.5vw, 24px);
  font-family: "Paragraph";
  padding: 5px 23px;
  background: var(--inchcape-blue);
  color: var(--inchcape-white);
}

.landing .btn:hover {
  background: #049ec9;
  color: var(--inchcape-white);
}

.landing .btn:active {
  background: var(--inchcape-blue);
  color: var(--inchcape-white);
  border-color: var(--inchcape-blue);
}

@media screen and (max-width: 768px) {
  .landing {
    padding: 2rem 2rem 4rem !important;
  }

  .landing .content {
    padding: 0 !important;
  }

  .landing img {
    width: max(40%, 200px);
  }

  .landing .title {
    font-size: min(10vw, 56px);
  }

  .landing .btn {
    font-size: min(7vw, 24px);
  }
}
