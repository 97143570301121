.book-success {
  padding: 68px 137px;
  background: url("../../img/car-bg.png") no-repeat bottom right;
}

.book-success-box {
  width: 100%;
  background: var(--inchcape-white);
  padding: 51px 103px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.book-success-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  margin-bottom: 114px;
}

.iBookingForApproval {
  font-family: "Title";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 20px;
  color: #16BAE7;
}

.book-success-content svg {
  width: 32px;
  height: 32px;
}

.book-success-content span {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: var(--inchcape-dark);
  vertical-align: middle;
}

.book-highlighted {
  font-family: "Title" !important;
  color: var(--inchcape-blue) !important;
}

.reminder-link {
  color: var(--inchcape-dark);
  text-decoration: none;
}

.book-success-content a:hover {
  color: var(--inchcape-blue);
}

.info-message {
  color: rgba(0, 35, 58, 0.9);
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
}

.home-book-btn {
  border: none;
  background-color: var(--inchcape-blue);
  border-radius: 4px;
  padding: 5px 26px;
}

.home-book-btn > span {
  font-size: 16px;
  font-family: "Paragraph";
  font-weight: 700;
  color: var(--inchcape-white);
}

.email-names {
  word-break: break-all;
}

.book-success .success-link {
  width: 100%;
  word-wrap: break-word;
}

@media screen and (max-width: 767px) {
  .book-success {
    padding: 30px 20px;
    background: url("../../img/car-bg.png") no-repeat bottom center;
  }

  .book-success-header img {
    width: 96px;
    height: 96px;
  }

  .book-success-header h3 {
    font-size: 22px;
    text-align: center;
  }

  .book-success-content span {
    font-size: 16px;
    white-space: normal;
  }

  .home-book-btn {
    width: 100%;
  }

  .book-success-header {
    margin-bottom: 50px;
  }

  .email-names {
    word-break: break-all;
  }
}
