/* whole dialog styling */
.approve-dialog div.k-dialog {
  padding: 51px 41px;
  width: 1200px !important;
  height: auto;
  border: 1px;
  border-radius: 10px;
}

/* dialog contents */
.approve-dialog div.k-dialog-content {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
}

/* input */

.approve-container {
  display: flex;
  padding-top: 35px;
  padding-bottom: 35px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.approve-dialog .k-label {
  padding-left: 4.6%;
  font-family: "Paragraph";
  font-size: 12px;
  line-height: 20px;
  color: #666666;
  white-space: noWrap;
}

.approve-dialog .k-input {
  width: 100%;
}

/* dialog title */
.approve-dialog .title,
.title-dialog {
  display: flex;
  gap: 22px;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  width: 80%;
  font-family: "Title";
  color: #00233A;
  text-align: center;
}

/* btn */

.approve-dialog .close-btn-container {
  text-align: right;
  width: 100%;
  margin-bottom: -30px;
}

.approve-dialog .close-btn-container>button {
  background-color: transparent;
  border: 0;
}

.approve-dialog .buttons {
  display: flex;
  gap: 20px;
}

.approve-dialog .Nobtn {
  box-sizing: border-box;
  font-family: "Paragraph";
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  gap: 4px;
  width: 128px;
  height: 30px;
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 2e-6) 0%,
      rgba(0, 0, 0, 0.02) 100%),
    #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.approve-dialog .Nobtn:hover {
  color: #424242;
  background: #e4e4e4;
  transition: all 0.3s ease-in-out;
}

.approve-dialog .Yesbtn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  gap: 4px;
  width: 128px;
  height: 30px;
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.04) 99.48%,
      rgba(0, 0, 0, 0.04) 99.49%),
    #16bae7;
  border: 1px solid #16bae7;
  border-radius: 4px;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.approve-dialog .Yesbtn:hover {
  color: var(--inchcape-white);
  background: #049ec9;
  transition: all 0.3s ease-in-out;
}

.approve-dialog .Yesbtn:active {
  background: var(--inchcape-blue);
  color: var(--inchcape-white);
  border-color: var(--inchcape-blue);
}

/* Table Grid */

.approve-dialog .k-table,
.approve-dialog .k-grid-table {
  width: 100% !important;
}

.inp>input {
  width: 204px;
  height: 20px;
  border: none;
  outline: none;
  font-family: "Paragraph";
  font-size: 14px;
  color: #666666;
}

.approve-dialog .k-window-content {
  overflow-x: hidden !important;
}

.approve-dialog .k-grid-content {
  overflow-y: hidden;
}

.approve-dialog .k-column-title {
  min-width: 0;
  text-overflow: ellipsis;
  font-family: "Title";
  color: rgba(0, 35, 58, 0.6);
  text-align: center;
  white-space: normal;
  flex-shrink: 1;
  white-space: nowrap;
}

.approve-dialog-title {
  font-size: 16px;
  font-weight: 700;
  font-family: "Title";
  font-style: normal;
  letter-spacing: 0.16px;
}

.approve-dialog .k-grid-header {
  padding-right: 0;
}

.approve-dialog .k-grid-header-wrap {
  border: 0;
}

.approve-dialog .k-table-md .k-table-td,
.k-table-md .k-table-th {
  padding: 10px;
}

.approve-dialog th.k-header label.k-checkbox-label {
  font-size: 14px;
  font-weight: 600px;
  font-family: "Title";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  /* Primary Inchcape/60% */
  color: rgba(0, 35, 58, 0.6);
  text-align: center;
}

.approve-dialog .k-checkbox:checked,
.approve-dialog .k-checkbox:checked:focus,
.approve-dialog .k-checkbox.k-checked.k-focus {
  color: #fff !important;
  border-color: rgb(22, 186, 231) !important;
  background-color: #16bae7 !important;
  box-shadow: 0 0 0 2px rgb(22 186 231 / 30%) !important;
}

.approve-dialog .k-grid td {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  /* Primary Inchcape/90% */
  color: rgba(0, 35, 58, 0.9);
  background-color: #fff;
  border: none;
  text-overflow: initial;
  white-space: initial;
  word-break: break-word;
}

.approve-dialog .k-grid th {
  border-width: 0 0 1px 0 !important;
  text-align: center;
}

.del {
  height: 10px;
  width: 10px;
}

.approve-dialog .k-cell-inner {
  justify-content: center;
}

.approve-dialog .k-grid-content {
  overflow-y: hidden;
}

.approve-dialog .k-grid-header {
  padding-right: 0;
  margin-bottom: 10px;
}

.approve-dialog .k-grid-header-wrap {
  border: 0;
}

.tbl {
  width: 100%;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
}

.tbl div.k-grid {
  margin-top: 0;
  border: none;
  width: 100%;
}

.approve-dialog-rep {
  margin-top: 0 !important;
}

/* change row color if selected */
.approve-dialog .k-grid td.k-selected,
.approve-dialog .k-grid tr.k-selected>td {
  background-color: #16bae755;
}

.approve-dialog .k-pager-numbers .k-selected .k-button-text {
  color: var(--inchcape-blue) !important;
}

.approve-dialog select.k-dropdown {
  display: none !important;
}

.approve-dialog .k-table-thead {
  background-color: #fff;
}

.approve-dialog .k-button-flat-primary {
  color: rgba(0, 35, 58, 0.5) !important;
}

.approve-dialog .k-grid-pager {
  border: none !important;
}

.approve-dialog .k-grid th {
  text-overflow: initial;
  white-space: initial;
  word-break: break-all;
}

.approve-dialog .k-grid th:last-child {
  word-break: normal;
}

.approve-dialog .k-grid td:first-child {
  text-overflow: clip;
}

.approve-dialog .mobile-table .line {
  width: 105%;
  gap: 10px;
  grid-template-columns: minmax(1fr, 120px) 1fr;
}

.k-grid tr.k-alt {
  background-color: transparent;
}

.k-grid tbody>tr:not(.k-detail-row):hover {
  background-color: transparent;
}

/* Pagination */
.approve-dialog .k-grid-pager {
  justify-content: center;
  border: none;
  background-color: var(--inchcape-white);
  margin-top: 24px;
  font-family: "Paragraph";
}

.approve-dialog .k-pager-sizes {
  flex-direction: row-reverse !important;
  gap: 20px;
}

.approve-dialog select.k-dropdown {
  display: none !important;
}

.approve-dialog .k-pager-numbers,
.approve-dialog .k-pager-sizes {
  display: flex;
}

.approve-dialog .k-pager-numbers .k-selected .k-button-text {
  color: var(--inchcape-blue) !important;
  /* background-color: #fff !important; */
}

.approve-dialog .k-button-flat-primary {
  color: rgba(0, 35, 58, 0.5) !important;
}

.approve-dialog .k-button.k-selected::before {
  /* background: #fff; */
  opacity: 0;
}

.approve-dialog .k-button-flat::after {
  box-shadow: none;
}

.approve-dialog .k-pager-sizes .k-dropdownlist {
  border: none !important;
  background-color: var(--inchcape-white) !important;
}

.approve-dialog .k-picker-solid:focus-within {
  box-shadow: none !important;
}

.k-pager-numbers.k-link.k-selected {
  /* background-color: var(--inchcape-white) !important; */
  color: var(--inchcape-blue) !important;
  border: 1px var(--inchcape-white) !important;
}

.k-pager-numbers .k-link:hover {
  background-color: var(--inchcape-white) !important;
}

.approve-dialog .k-pager-numbers .k-link:focus {
  box-shadow: none !important;
  color: var(--inchcape-blue) !important;
  background-color: var(--inchcape-white) !important;
}

.approve-dialog .k-pager-numbers .k-link {
  color: rgba(0, 35, 58, 0.5) !important;
  cursor: pointer !important;
}

.k-pager-nav.k-link:focus {
  background-color: var(--inchcape-white) !important;
  box-shadow: none !important;
}

.k-pager-nav.k-link:hover {
  background-color: rgba(22, 186, 231, 0.3) !important;
  box-shadow: none !important;
  border-radius: 50px;
}

.approve-dialog .overlay {
  grid-area: 1 / 1 / -2 / -2;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.77);
  user-select: none;
}

.approve-dialog .overlay .k-loader,
.approve-dialog .overlay p {
  color: var(--inchcape-blue);
  font-size: 24px;
  font-weight: 700;
  font-family: "Paragraph";
}

/* mobile view  */

@media screen and (max-width: 991.9px) {
  .approve-dialog div.k-dialog {
    width: 95% !important;
  }
}