.reminder {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 45px;
  background: #ffffff;
}

.reminder .contents {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  justify-items: center;
  column-gap: 45px;
  row-gap: 26px;
  border: 1px solid var(--inchcape-blue);
  margin-bottom: 30px;
  padding: min(1.7vw, 55px) min(3vw, 81px);
}

.reminder .offices {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  padding: 20px 24px;
  height: 110px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: inset 0 90px 20px 0 rgba(150, 150, 150, 0.2),
    inset 0 -25px 40px 2px #00233a;
}

.reminder .contents p {
  text-align: center;
  color: #ffffff;
  font-family: "Title";
  font-weight: 700;
  font-size: max(0.73vw, 16px);
  margin: 0;
  padding: 0;
}

.reminder .create {
  border: none;
}

.reminder .contents svg,
.reminder .create svg {
  cursor: pointer;
  width: 16px;
  height: 16px;
  color: #ffffff;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.reminder div.k-notification-success,
.reminder div.k-notification-error {
  position: fixed;
  display: flex;
  gap: 4px;
  top: 10vh;
  right: 5vw;
}

.reminder .k-animation-container-relative {
  position: fixed;
}

@media screen and (max-width: 767px) {
  .reminder .contents {
    display: flex;
    flex-direction: column;
    padding: 30px 33px;
  }

  .reminder .offices {
    height: 75px;
    align-items: center;
    box-shadow: inset 0 50px 10px 0 rgba(150, 150, 150, 0.2),
      inset 0 -20px 30px 2px #00233a;
  }

  .reminder .create svg,
  .reminder .contents svg {
    margin-bottom: 3px;
  }
}

/* start here */
.reminder .k-column-title {
  height: 34px;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  /* Primary Inchcape/60% */
  color: rgba(0, 35, 58, 0.6);
  min-width: 0;
  font-family: "Title";
  white-space: initial;
  flex-shrink: 1;
  overflow: hidden;
}
/* Style for label available seats */

.reminderHistory .k-grid-pager {
  font-family: "Paragraph";
}

/* For last div */
.reminderHistory {
  padding: 0px 0px 20px 0px;
  width: 100%;
}

.reminderHistory .k-grid-content {
  overflow-y: hidden;
}

.reminderHistory > div {
  width: 100%;
}
/* for table */
.rehistory {
  display: flex;
  padding: 40px 0px 0px 5px;
}
.rehistory .k-grid {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
/* Pagination */
.reminder .k-grid-pager {
  /* flex-direction: row-reverse !important; */
  justify-content: center;
}

.reminder .k-pager-sizes {
  flex-direction: row-reverse !important;
  gap: 20px;
}
.reminder .k-pager-md .k-pager-info,
.k-pager-sm .k-pager-info,
.k-pager-sm .k-pager-sizes,
.k-pager-sm .k-pager-numbers {
  display: flex;
}
.reminder .k-pager-numbers .k-selected {
  color: #16bae7 !important;
}

.reminder .k-button-flat-primary {
  color: rgba(0, 35, 58, 0.5) !important;
}

.reminder .k-pager-sizes .k-dropdownlist {
  border: none !important;
  background-color: #ffffff !important;
}

.reminder .k-picker-solid:focus-within {
  box-shadow: none !important;
}

.reminder .k-pager-numbers .k-link.k-selected {
  background-color: #ffffff !important;
  color: #16bae7 !important;
  border: 1px #ffffff !important;
}

.reminder .k-pager-numbers .k-link:hover {
  background-color: #ffffff !important;
}

.reminder .k-pager-numbers .k-link:focus {
  box-shadow: none !important;
  color: #16bae7 !important;
}

.reminder .k-pager-numbers .k-link {
  color: rgba(0, 35, 58, 0.5) !important;
  cursor: pointer !important;
}

.reminder .k-pager-nav.k-link:focus {
  background-color: #ffffff !important;
  box-shadow: none !important;
}

.reminder .k-pager-nav.k-link:hover {
  background-color: rgba(22, 186, 231, 0.3) !important;
  box-shadow: none !important;
  border-radius: 50px;
}
.Add3 {
  color: #16bae7;
  width: 25px;
  height: 25px;
}

/* for history */
.reminderHistory-title {
  font-size: 20px;
  font-weight: 700;
  font-family: "Title";
  font-style: normal;
  border-bottom: 1px solid #00233a;
  padding-bottom: 10px;
  margin: 0;
}
.reminder .k-widget,
.reminder .k-grid {
  border: none !important;
  width: 100%;
}

.reminder .k-grid-header {
  font-family: "Title";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  padding: 0;
  color: rgba(0, 35, 58, 0.6) !important;
}

.reminder .k-grid tr {
  background: #fff;
  position: relative;
}

.reminder .k-header > .k-cell-inner {
  margin: 0px 0px !important;
}

.reminder .k-header,
.reminder .k-header {
  border: none !important;
  border-bottom: 1px solid #ebeff2 !important;
}

.reminder .k-cell-inner {
  justify-content: center !important;
  margin: 0;
  padding: 0;
}

.reminder .k-cell-inner > .k-link {
  padding: 0px !important;
}

.reminder .k-link {
  cursor: normal !important;
}

.reminder .k-master-row,
.reminder .k-alt {
  border-right: none !important;
}

.reminder .k-grid tbody {
  text-align: center !important;
}

.reminder .k-grid th {
  padding: 15px 6px !important;
}

.reminder .k-grid td {
  border: none !important;
  padding: 15px 22px !important;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  color: rgba(0, 35, 58, 0.9) !important;
  background-color: #fff;
  border: none;
}
.reminder .k-pager-numbers .k-link.k-selected .k-button-text {
  color: var(--inchcape-blue) !important;
}
.reminder select.k-dropdown {
  display: none !important;
}
.reminder .k-button-flat-primary {
  color: rgba(0, 35, 58, 0.5) !important;
  border: none !important;
}

.reminder .k-grid-pager {
  border: none !important;
}
.reminder .k-grid th {
  border-width: 0 0 1px 0 !important;
}
.reminder.k-pager-wrap,
.reminder .k-pager {
  border-color: rgba(0, 0, 0, 0.08);
  color: #212529;
  background-color: #ffffff;
}
.reminder .k-pager-numbers {
  display: block;
}
.reminder .k-grid-th {
  border-width: 0 0 1px 0;
}

/* Pagination */
.reminder .k-grid-pager {
  /* flex-direction: row-reverse !important; */
  justify-content: center;
}

.reminder .k-pager-sizes {
  flex-direction: row-reverse !important;
  gap: 20px;
}

.reminder .k-pager-numbers .k-selected {
  color: #16bae7 !important;
}

.reminder .k-button-flat-primary {
  color: rgba(0, 35, 58, 0.5) !important;
}

.reminder .k-pager-sizes .k-dropdownlist {
  border: none !important;
  background-color: #ffffff !important;
}

.reminder .k-picker-solid:focus-within {
  box-shadow: none !important;
}

.reminder .k-pager-numbers .k-link.k-selected {
  background-color: #ffffff !important;
  color: #16bae7 !important;
  border: 1px #ffffff !important;
}

.reminder .k-pager-numbers .k-link:hover {
  background-color: #ffffff !important;
}

.reminder .k-pager-numbers .k-link:focus {
  box-shadow: none !important;
  color: #16bae7 !important;
}

.reminder .k-pager-numbers .k-link {
  color: rgba(0, 35, 58, 0.5) !important;
  cursor: pointer !important;
}

.reminder .k-pager-nav.k-link:focus {
  background-color: #ffffff !important;
  box-shadow: none !important;
}
.reminder .k-notification-success {
  display: flex;
  gap: 4px;
  height: 27px;
  position: fixed;
  font-family: "Sub Title";
  top: 8vh;
  right: 5vw;
  border-color: #37b400;
  color: white;
  background-color: #37b400;
}
.reminder .k-pager-nav.k-link:hover {
  background-color: rgba(22, 186, 231, 0.3) !important;
  box-shadow: none !important;
  border-radius: 50px;
}

.reminder .k-table {
  width: 100% !important;
}

.reminder .k-grid th,
.reminder .k-grid td {
  text-overflow: initial;
  white-space: normal;
  word-break: break-word;
}

@media screen and (max-width: 991.9px) {
  .reminder .seatcapacity-title {
    padding-bottom: 0;
    font-size: min(7vw, 20px);
  }

  .floorlayout-btn {
    padding: 5px 8px;
  }

  .floorlayout-btn,
  .floorlayout-popout {
    height: auto;
    width: min(35vw, 186px);
    gap: 8px;
    flex-wrap: wrap;
  }

  .floorlayout-btn svg {
    width: 16px;
    height: 16px;
  }

  .floorlayout-btn > span {
    width: auto;
  }

  .reminder .row {
    padding: 0;
    margin: 0;
  }

  .reminder .row > *:not(.inputDate):not(.lblAvailableseats),
  .reminder .inputDate .k-daterangepicker label {
    padding: 0 5px !important;
  }

  .reminder .mobile-table .line {
    width: 105%;
    gap: 10px;
    grid-template-columns: minmax(1fr, 120px) 1fr;
  }

  .reminder .mobile-table .top > div,
  .reminder .mobile-table .bot > div {
    gap: 18px;
  }

  .reminder .mobile-table .line svg {
    color: #e40521;
  }

  .reminder .mobile-table .bot > div {
    padding-top: 0;
  }

  .reminder .mobile-table label {
    font-family: "Title";
    color: rgba(0, 35, 58, 0.6);
  }

  .reminder .mobile-table p {
    max-width: 100%;
    font-family: "Sub Title";
    font-size: 14px;
    color: rgba(0, 35, 58, 0.9);
    word-break: break-all;
  }
}