.dialog-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.dialog-title {
  font-family: "Paragraph";
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 37px 54px 0px 54px; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #424242;
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-btn {
  display: flex;
  border: none;
  padding: 5px 55px;
}

.btn-no {
  margin-right: 8px;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  color: #424242;
}

.btn-no:hover {
  color: #424242;
  background: #e4e4e4;
  transition: all 0.3s ease-in-out;
}

.btn-yes {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--inchcape-white);
  border: 1px solid var(--inchcape-blue);
  background: var(--inchcape-blue);
  border-radius: 4px;
}

.btn-yes:hover {
  color: var(--inchcape-white);
  background: #049ec9;
  transition: all 0.3s ease-in-out;
}

.home-history-dialog .book-cancel-dialog,
.home-history-dialog .k-dialog {
  width: 448px !important;
  height: 172px !important;
}

.book-cancel-dialog .k-window-content {
  padding: 53px 70px 39px 70px !important;
}

@media screen and (max-width: 767px) {
  .dialog-title {
    text-align: center;
  }

  .book-cancel-dialog .k-window-content {
    width: 332px;
  }
}
