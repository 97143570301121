.dropdown-multiselect p {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  padding: 0 6px 0 3px;
}

.dropdown-input {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.hide {
  display: none;
}

.dropdown-input:focus,
.dropdown-input:focus-within {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}

.dropdown-input svg {
  width: 20px;
  height: 20px;
  color: var(--inchcape-label);
  margin-bottom: 2px;
}

.dropdown-input-text {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: var(--inchcape-label);
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 8px;
}

.dropdown-icon {
  padding: 2px 8px 2px 8px;
  justify-self: center;
  align-self: center;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 2e-6) 0%,
      rgba(0, 0, 0, 0.02) 100%
    ),
    #f5f5f5;
}

.dropdown-icon:hover {
  background: #ebebeb;
}

.dropdown-multiselect select {
  width: 100%;
  height: 0px;
  position: absolute;
  cursor: pointer;
  top: 30px;
  left: 0;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  z-index: 5;
  transition: height 0.7s ease;
  overflow: hidden;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
}

.dropdown-multiselect select.active {
  height: 226px;
  overflow: auto;
}

.dropdown-multiselect select:focus-visible {
  outline: rgba(0, 0, 0, 0.08);
}

.dropdown-multiselect option {
  padding: 4px 8px;
}

.dropdown-multiselect option:hover {
  background: var(--inchcape-blue) !important;
  color: var(--inchcape-white);
}

.dropdown-multiselect option:focus,
.dropdown-multiselect option:checked {
  background: var(--inchcape-blue) !important;
  color: var(--inchcape-white);
}

.dropdown-disabled {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}

.dropdown-multiselect option:disabled {
  cursor: default;
  color: #a5abb3;
}

.dropdown-multiselect option:disabled:hover {
  background: var(--inchcape-white) !important;
  color: #a5abb3;
}

@media (max-width: 767px) {
  .dropdown-multiselect p,
  .dropdown-input-text,
  .dropdown-multiselect select {
    font-size: 14px;
    line-height: 16px;
  }
  
  .dropdown-input svg {
    width: 16px;
    height: 16px;
  }
  
  .dropdown-multiselect select {
    font-size: 14px;
    line-height: 16px;
  }
}
