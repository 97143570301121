.history-module {
  display: flex;
  flex-direction: column;
  padding: 34px 40px 20px 40px;
  height: auto;
  background: var(--inchcape-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.history-title {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Title";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  border-bottom: 1px solid var(--inchcape-dark);
  padding-bottom: 10px;
  margin: 0;
}

.history {
  display: grid;
  grid-template-rows: 1fr;
}

.history-module .k-column-title {
  white-space: normal !important;
  font-family: "Title";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  color: rgba(0, 35, 58, 0.6);
}

.history-module .k-grid thead {
  text-align: center !important;
  padding: 0;
  background-color: var(--inchcape-white);
}

.history-module .k-grid table {
  width: 100% !important;
  padding: 0 7px;
}

.history-module .k-grid {
  border: none !important;
  /* width: 890px !important; */
  grid-area: 1 / 1 / -2 / -2 !important;
}

.history-module .k-grid-norecords {
  display: none;
}

.history-module td {
  vertical-align: top !important;
}

.history-module .k-grid tr,
.k-grid tr.k-alt:hover {
  background-color: transparent !important;
}

.history-module .k-grid td {
  padding: 16px 0px !important;
}

.history-module .k-grid-header {
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 35, 58, 0.6) !important;
}

.history-module .k-header > .k-cell-inner {
  margin: 0px 0px !important;
}

.history-module .k-header {
  border: none !important;
  border-bottom: 1px solid #ebeff2 !important;
}

.history-module .k-cell-inner {
  justify-content: center !important;
}

.history-module .k-grid-header .k-header {
  vertical-align: top;
}

.history-module .k-grid-header {
  background-color: var(--inchcape-white) !important;
}

.history-module .k-cell-inner > .k-link {
  padding: 0px !important;
}

.history-module .k-link {
  cursor: default !important;
}

.history-module .k-master-row,
.history-module .k-alt {
  border-right: none !important;
}

.history-module .k-grid th {
  padding: 15px 0px 15px 3px !important;
}

.history-module .k-grid td {
  border: none !important;
  font-family: "Paragraph" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 35, 58, 0.9) !important;
  word-break: break-word !important;
}

.history-module .k-table-td {
  white-space: normal;
}

.history-module th.k-header:nth-child(7),
.history-module th.k-header:nth-child(8) {
  padding: 15px 0px !important;
}

.history-module .k-table-tbody .k-table-row:hover,
.history-module .k-table-tbody .k-table-row:hover.k-table-alt-row {
  background: var(--inchcape-sidebar-hover) !important;
}

.cancel {
  display: flex;
  justify-content: center;
}

.cancel-btn {
  cursor: pointer;
  margin-top: 12px;
  width: 2rem;
  color: var(--inchcape-blue) !important;
}

.cancelled-btn {
  cursor: normal;
  margin-top: 12px;
  width: 2rem;
  color: #dedede !important;
}

/* History book log Status */
.active-status {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--inchcape-green);
}

.cancel-status {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--inchcape-red);
}

.pending-status {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--inchcape-blue);
}

/* Pagination */
.history-module .k-grid-pager {
  justify-content: center;
  border: none;
  background-color: var(--inchcape-white);
  margin-top: 24px;
  font-family: "Paragraph";
}

.history-module .k-pager-sizes {
  flex-direction: row-reverse !important;
  gap: 20px;
}

.history-module select.k-dropdown {
  display: none !important;
}

.history-module .k-pager-numbers,
.history-module .k-pager-sizes {
  display: flex;
}

.history-module .k-pager-numbers .k-selected .k-button-text {
  color: var(--inchcape-blue) !important;
  /* background-color: #fff !important; */
}

.history-module .k-button-flat-primary {
  color: rgba(0, 35, 58, 0.5) !important;
}

.history-module .k-button.k-selected::before {
  /* background: #fff; */
  opacity: 0;
}

.history-module .k-button-flat::after {
  box-shadow: none;
}

.history-module .k-pager-sizes .k-dropdownlist {
  border: none !important;
  background-color: var(--inchcape-white) !important;
}

.history-module .k-picker-solid:focus-within {
  box-shadow: none !important;
}

.k-pager-numbers.k-link.k-selected {
  /* background-color: var(--inchcape-white) !important; */
  color: var(--inchcape-blue) !important;
  border: 1px var(--inchcape-white) !important;
}

.k-pager-numbers .k-link:hover {
  background-color: var(--inchcape-white) !important;
}

.history-module .k-pager-numbers .k-link:focus {
  box-shadow: none !important;
  color: var(--inchcape-blue) !important;
  background-color: var(--inchcape-white) !important;
}

.history-module .k-pager-numbers .k-link {
  color: rgba(0, 35, 58, 0.5) !important;
  cursor: pointer !important;
}

.k-pager-nav.k-link:focus {
  background-color: var(--inchcape-white) !important;
  box-shadow: none !important;
}

.k-pager-nav.k-link:hover {
  background-color: rgba(22, 186, 231, 0.3) !important;
  box-shadow: none !important;
  border-radius: 50px;
}

.history-module .overlay {
  grid-area: 1 / 1 / -2 / -2;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.77);
  user-select: none;
}

.history-module .overlay .k-loader,
.history-module .overlay p {
  color: var(--inchcape-blue);
  font-size: 24px;
  font-weight: 700;
  font-family: "Paragraph";
}

@media screen and (max-width: 991px) {
  .history-module,
  .history-title {
    width: 100%;
    border-bottom: 0;
  }

  .history-module .mobile-table {
    grid-area: 1 /1 / -2 / -2;
  }

  .history-module .mobile-table .line {
    width: 105%;
    gap: 10px;
    grid-template-columns: minmax(1fr, 90px) 1fr;
  }

  .history-module .mobile-table .top > div,
  .history-module .mobile-table .bot > div {
    gap: 18px;
  }

  .history-module .mobile-table .line svg {
    color: var(--inchcape-blue);
  }

  .history-module .mobile-table .bot > div {
    padding-top: 0;
  }

  .history-module .mobile-table label {
    font-family: "Title";
    color: rgba(0, 35, 58, 0.6);
  }

  .history-module .mobile-table p {
    font-family: "Paragraph";
    font-size: 14px;
    color: rgba(0, 35, 58, 0.9);
    word-break: break-all;
  }

  .cancel-btn {
    margin-top: 0;
  }
}

@media screen and (max-width: 767.9px) {
  .history-module {
    padding: 2rem 1rem;
  }
  .cancel-btn {
    margin-top: 0;
  }
}
