/* Size of panel of list-of-booking-to-approve */
.list-of-booking-to-approve {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.inp {
  align-self: flex-end;
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px 5px 0px;
  width: 237px;
  height: 30px;
  background: #ffffff;
  gap: 4px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.list-of-booking-to-approve .k-table,
.list-of-booking-to-approve .k-grid-table {
  width: 100% !important;
}

.inp > input {
  width: 204px;
  height: 20px;
  border: none;
  outline: none;
  font-family: "Paragraph";
  font-size: 14px;
  color: #666666;
}

.list-of-booking-to-approve .k-grid-content {
  overflow-y: hidden;
}

.list-of-booking-to-approve .k-column-title {
  min-width: 0;
  text-overflow: ellipsis;
  font-family: "Title";
  color: rgba(0, 35, 58, 0.6);
  text-align: center;
  white-space: normal;
  flex-shrink: 1;
  overflow: hidden;
  white-space: nowrap;
}

.list-of-booking-to-approve-title {
  font-size: 16px;
  font-weight: 700;
  font-family: "Title";
  font-style: normal;
  margin-bottom: 4.5px;
  letter-spacing: 0.16px;
  line-height: 10px;
}

.list-of-booking-to-approve .k-grid-header {
  padding-right: 0;
}

.list-of-booking-to-approve .k-grid-header-wrap {
  border: 0;
}

.list-of-booking-to-approve .k-table-md .k-table-td,
.k-table-md .k-table-th {
  padding: 10px;
}

.list-of-booking-to-approve th.k-header label.k-checkbox-label {
  font-size: 14px;
  font-weight: 600px;
  font-family: "Title";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  /* Primary Inchcape/60% */
  color: rgba(0, 35, 58, 0.6);
  text-align: center;
}

.list-of-booking-to-approve .k-checkbox:checked,
.list-of-booking-to-approve .k-checkbox:checked:focus,
.list-of-booking-to-approve .k-checkbox.k-checked.k-focus {
  color: #fff !important;
  border-color: rgb(22, 186, 231) !important;
  background-color: #16bae7 !important;
  box-shadow: 0 0 0 2px rgb(22 186 231 / 30%) !important;
}

.list-of-booking-to-approve .k-grid td {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  /* Primary Inchcape/90% */
  color: rgba(0, 35, 58, 0.9);
  background-color: #fff;
  border: none;
  text-overflow: initial;
  white-space: initial;
  word-break: break-word;
}

.list-of-booking-to-approve .k-grid th {
  border-width: 0 0 1px 0 !important;
  text-align: center;
}

.del {
  height: 10px;
  width: 10px;
}

.list-of-booking-to-approve .k-cell-inner {
  justify-content: center;
}

.list-of-booking-to-approve .k-grid-content {
  overflow-y: hidden;
}

.list-of-booking-to-approve .k-grid-header {
  padding-right: 0;
  margin-bottom: 10px;
}

.list-of-booking-to-approve .k-grid-header-wrap {
  border: 0;
}

.tbl {
  width: 100%;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
}

.tbl div.k-grid {
  margin-top: 0;
  border: none;
  width: 100%;
}

.history-rep {
  margin-top: 0 !important;
}

/* change row color if selected */
.list-of-booking-to-approve .k-grid td.k-selected,
.list-of-booking-to-approve .k-grid tr.k-selected > td {
  background-color: #16bae755;
}

.list-of-booking-to-approve .k-pager-numbers .k-selected .k-button-text {
  color: var(--inchcape-blue) !important;
}

.list-of-booking-to-approve select.k-dropdown {
  display: none !important;
}

.list-of-booking-to-approve .k-table-thead {
  background-color: #fff;
}

.list-of-booking-to-approve .k-button-flat-primary {
  color: rgba(0, 35, 58, 0.5) !important;
}

.list-of-booking-to-approve .k-grid-pager {
  border: none !important;
}

.list-of-booking-to-approve .k-grid th {
  text-overflow: initial;
  white-space: initial;
  word-break: break-all;
}

.list-of-booking-to-approve .k-grid th:last-child {
  word-break: normal;
}

.list-of-booking-to-approve .k-grid td:first-child {
  text-overflow: clip;
}

.list-of-booking-to-approve.k-pager-wrap,
.list-of-booking-to-approve .k-pager {
  border-color: rgba(0, 0, 0, 0.08);
  color: #212529;
  background-color: #ffffff;
}

.list-of-booking-to-approve .k-grid-pager {
  justify-content: center;
}

.list-of-booking-to-approve .k-pager-info {
  flex: none !important;
}

.list-of-booking-to-approve .k-pager-numbers {
  display: block;
}

.list-of-booking-to-approve .k-pager-numbers .k-link:focus {
  box-shadow: none !important;
}

.list-of-booking-to-approve .k-pager-numbers .k-link:hover {
  background-color: #fff !important;
  color: #16bae7;
}

.list-of-booking-to-approve .k-pager-numbers .k-link {
  color: #424242;
}

.list-of-booking-to-approve
.history-module 
.k-pager-numbers 
.k-selected 
.k-button-text {
  color: var(--inchcape-blue) !important;
  /* background-color: #fff !important; */
}

.list-of-booking-to-approve ul.pagination {
  height: 26px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.admin .k-notification-success {
  display: flex;
  gap: 4px;
  height: 27px;
  position: fixed;
  top: 8vh;
  font-family: "Sub Title";
  right: 5vw;
  border-color: #37b400;
  color: white;
  background-color: #37b400;
}

.list-of-booking-to-approve .set-inp {
  display: flex;
  justify-content: space-between;
}

.list-of-booking-to-approve .btn:disabled,
.list-of-booking-to-approve .btn.disabled {
  background: #16bae7;
  border: 1px solid #16bae7;
  color: #fff;
}

.list-of-booking-to-approve .k-button-flat.k-selected {
  color: #16bae7 !important;
}

.list-of-booking-to-approve .k-table-tbody .k-table-row.k-selected,
.list-of-booking-to-approve .k-table-list .k-table-row.k-selected {
  background-color: transparent;
}

/* .list-of-booking-to-approve td {
    min-width: 50px;
    text-overflow: ellipsis;
  } */

@media screen and (max-width: 991.9px) {
  .list-of-booking-to-approve .mobile-table {
    border: none;
    margin-bottom: 4rem;
  }

  /* .list-of-booking-to-approve .mobile-table .top {
    border: 2px solid #16bae7;
  }

  .list-of-booking-to-approve .mobile-table .bot {
    border: 1px solid #414141;
  } */

  .list-of-booking-to-approve .mobile-table .line {
    width: 105%;
    gap: 10px;
    grid-template-columns: minmax(1fr, 120px) 1fr;
  }

  .list-of-booking-to-approve .mobile-table .k-checkbox-md {
    width: 30px;
    height: 30px;
    /* margin: 12px 0 20px; */
    display: flex;
    flex-direction: column;
    bottom: 10px;
  }

  .list-of-booking-to-approve .mobile-table .top>div,
  .list-of-booking-to-approve .mobile-table .bot>div {
    gap: 5px;
  }

  .list-of-booking-to-approve .mobile-table label {
    font-family: "Title";
    color: rgba(0, 35, 58, 0.6);
    text-align: start;
    font-size: 14px;
  }

  .list-of-booking-to-approve .mobile-table p {
    max-width: 100%;
    font-family: "Sub Title";
    font-size: 13px;
    word-break: break-all;
    color: rgba(0, 35, 58, 0.9);
    text-align: start;
  }
}

@media screen and (max-width: 767.9px) {
  .list-of-booking-to-approve {
    width: 100%;
  }

  .list-of-booking-to-approve .tbl {
    width: 100%;
  }

  .list-of-booking-to-approve .set-inp {
    flex-direction: column;
    gap: 11px;
  }

  .list-of-booking-to-approve .inp {
    width: 100%;
    order: -1;
  }
}

.k-switch-on .k-switch-track {
  border-color: #16bae7;
  background-color: #16bae7;
}

.k-grid tr.k-alt {
  background-color: transparent;
}

.k-grid tbody > tr:not(.k-detail-row):hover  {
  background-color: transparent;
}

.list-of-booking-to-approve .buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.list-of-booking-to-approve .rejectbtn {
  box-sizing: border-box;
  font-family: "Paragraph";
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 4px;
  width: 128px;
  height: 30px;
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 2e-6) 0%,
      rgba(0, 0, 0, 0.02) 100%),
    #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  color: #16bae7;
  border-color: #16bae7;
  background-color: transparent;
}

.list-of-booking-to-approve .rejectbtn:hover {
  color: var(--inchcape-white);
  background: var(--inchcape-blue);
  transition: all 0.3s ease-in-out;
}

.list-of-booking-to-approve .rejectbtn:disabled {
  background: var(--inchcape-white);
  border: 1px solid #16bae7;
  color: var(--inchcape-blue);
}

.list-of-booking-to-approve .approve {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 4px;
  width: 128px;
  height: 30px;
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.04) 99.48%,
      rgba(0, 0, 0, 0.04) 99.49%),
    #16bae7;
  border: 1px solid #16bae7;
  border-radius: 4px;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.list-of-booking-to-approve .approve:hover {
  color: var(--inchcape-white);
  background: #049ec9;
  transition: all 0.3s ease-in-out;
}

.list-of-booking-to-approve .approve:active {
  background: var(--inchcape-blue);
  color: var(--inchcape-white);
  border-color: var(--inchcape-blue);
}

.list-of-booking-to-approve .overlay {
  grid-area: 1 / 1 / -2 / -2;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.77);
  user-select: none;
}

.list-of-booking-to-approve .overlay .k-loader,
.list-of-booking-to-approve .overlay p {
  color: var(--inchcape-blue);
  font-size: 24px;
  font-weight: 700;
  font-family: "Paragraph";
  margin-bottom: 20px;
  margin-top: 20px;
}