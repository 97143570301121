.country-sq {
  background: var(--inchcape-white);
  width: 141px;
  height: 122px;
  padding: 20px 40px 10px;
  gap: 20px;
  cursor: pointer;
  border: 3px solid transparent;
}

.country-sq.selected {
  border: 3px solid #16bae7;
}

.country-sq p {
  font-family: "Title";
  font-size: 1rem;
}
