/* whole dialog styling */
.enableDisableDialog div.k-dialog {
  padding: 20px;
  width: 442px;
  height: auto;
  border: 1px;
  border-radius: 17px;
}

/* dialog contents */
.enableDisableDialog div.k-dialog-content {
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
}

.enableDisableDialog .close-btn-container {
  text-align: right;
  width: 100%;
  margin-bottom: -30px;
}

.enableDisableDialog .close-btn-container>button {
  background-color: transparent;
  border: 0;
}

.enableDisableDialog .k-svg-icon>svg {
  color: #667b89;
}

.enableDisableDialog .img-container>img {
  width: 104px;
  height: 104px;
  margin: 20px 9px 20px 9px;
}

/* dialog title */
.enableDisableDialog .title {
  font-family: "Title";
  display: flex;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  line-height: 29.5px;
  color: #00233A;
}

.enableDisableDialog .buttons {
  display: flex;
  gap: 20px;
}

.enableDisableDialog .Nobtn {
  box-sizing: border-box;
  font-family: "Paragraph";
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  gap: 4px;
  width: 128px;
  height: 30px;
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 2e-6) 0%,
      rgba(0, 0, 0, 0.02) 100%),
    #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.enableDisableDialog .Nobtn:hover {
  color: #424242;
  background: #e4e4e4;
  transition: all 0.3s ease-in-out;
}

.enableDisableDialog .Yesbtn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  gap: 4px;
  width: 128px;
  height: 30px;
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.04) 99.48%,
      rgba(0, 0, 0, 0.04) 99.49%),
    #16bae7;
  border: 1px solid #16bae7;
  border-radius: 4px;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.enableDisableDialog .Yesbtn:hover {
  color: var(--inchcape-white);
  background: #049ec9;
  transition: all 0.3s ease-in-out;
}

.enableDisableDialog .Yesbtn:active {
  background: var(--inchcape-blue);
  color: var(--inchcape-white);
  border-color: var(--inchcape-blue);
}

.enableDisableDialog .maxApprover {
  padding: 35px 0px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Paragraph";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.14px;
}