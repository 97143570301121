@font-face {
  font-family: "Title";
  src: url("../fonts/ITCAvantGardeStd-Demi.ttf");
}

@font-face {
  font-family: "Sub Title";
  src: url("../fonts/ITCAvantGardeStd-Md.ttf");
}

@font-face {
  font-family: "Paragraph";
  src: url("../fonts/ITCAvantGardeStd-Bk.ttf");
}

:root {
  --inchcape-blue: #16bae7;
  --inchcape-yellow: #b7cd00;
  --inchcape-red: #e40521;
  --inchcape-green: #37b400;
  --inchcape-purple: #9e61a4;
  --inchcape-dark: #00233a;
  --inchcape-dark-opacity: rgba(0, 35, 58, 0.6);
  --inchcape-sidebar-hover: rgba(22, 186, 231, 0.12);
  --inchcape-white: #ffffff;
  --inchcape-label: #424242;
  --inchcale-sidebar-texts: #324054;
  --inchcape-placeholder: #666666;
  --inchcape-disabled: #a5abb3;
}

.main {
  padding: 0;
  margin: 0;
  height: 100vh;
  overflow: hidden;
}

.content-cont {
  width: 100%;
  overflow: auto;
}

body {
  background-color: #fafafa;
}


p {
  margin: 0;
  padding: 0;
}

.right-content {
  overflow: auto;
}

@media screen and (max-width: 767px) {
  .main {
    min-height: 100vh;
    height: auto;
  }
}
