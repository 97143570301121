.co-dialog-countries {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  max-width: 417px;
  max-height: 582px;
  overflow-y: auto;
}

.co-dialog-countries .overlay {
  grid-area: 1 / 1 / -2 / -2;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.77);
  user-select: none;
}

.co-dialog-countries .overlay .k-loader,
.co-dialog-countries .overlay p {
  color: #16bae7;
  font-size: 20px;
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-family: "Paragraph";
}

.co-dialog-countries .contents {
  padding: 30px 54px 35px;
  grid-area: 1 / 1 / -2 / -2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.co-dialog-countries h3 {
  font-family: "Title";
  font-weight: 700;
  font-size: 26px;
  color: #16bae7;
  margin-bottom: 25px;
  text-align: center;
}

.co-dialog-countries .country-name,
.co-dialog-countries .cities,
.co-dialog-countries .flag {
  width: 100%;
}

.co-dialog-countries .flag {
  margin-top: 12px;
}

.co-dialog-countries .k-input {
  border: 1px solid rgba(66, 66, 66, 0.5);
  padding: 5px 4px 5px 9px;
  height: 30px;
  color: #666666;
  font-family: "Paragraph";
  width: 100%;
  font-size: 13px;
}

.co-dialog-countries .imgs {
  display: grid;
  grid-template-columns: repeat(4, minmax(47px, auto));
  grid-template-rows: minmax(47px, 1fr);
  margin-top: 20px;
  justify-content: center;
  margin-bottom: 20px;
}

.co-dialog-countries .imgs > .unselected {
  border: 2px solid transparent;
  padding: 7px;
  border-radius: 50%;
  cursor: pointer;
}

.co-dialog-countries .imgs > .selected {
  border: 2px solid #16bae7;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
  padding: 7px;
  border-radius: 50%;
  cursor: pointer;
}

.co-dialog-countries-btns {
  display: flex;
  gap: 8px;
  width: 264px;
  justify-content: center;
}

.co-dialog-countries-btns > button {
  width: 128px;
  height: 30px;
  padding: 5px 9px;
  border-radius: 4px;
  font-family: "Paragraph";
  font-size: 14px;
}

.co-dialog-countries-btns > button:first-child {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 2e-6) 0%,
      rgba(0, 0, 0, 0.02) 100%
    ),
    #f5f5f5;
  color: #424242;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.co-dialog-countries-btns > button:first-child:hover {
  color: #424242;
  background: #e4e4e4;
  transition: all 0.3s ease-in-out;
}

.co-dialog-countries-btns > button:nth-child(2) {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.04) 99.48%,
      rgba(0, 0, 0, 0.04) 99.49%
    ),
    #16bae7;
  color: #fff;
  border: 1px solid #16bae7;
}

.co-dialog-countries-btns > button:nth-child(2):hover {
  color: var(--inchcape-white);
  background: #049ec9;
  transition: all 0.3s ease-in-out;
}

.co-dialog-countries .flag > label.k-label {
  font-family: "Paragraph";
  font-size: 13px;
  color: #666666;
  line-height: 20px;
}

.co-dialog-countries label:not(.flag label) {
  padding-left: 4.6%;
  font-family: "Paragraph";
  font-size: 11.8px;
  line-height: 20px;
  color: #666666;
}

.co-dialog-countries input.k-input {
  font-family: "Paragraph";
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  padding: 6px 4px 5px 9px;
  height: 30px;
  border: 1px solid rgba(66, 66, 66, 0.5);
}

.co-dialog-countries input.k-input::placeholder {
  font-family: "Paragraph";
  font-size: 14px;
  line-height: 20px;
  color: #666666;
}

.co-dialog-countries .cities {
  display: flex;
  align-items: flex-start;
}

.co-dialog-countries .cities svg {
  margin-top: 2.4rem;
  cursor: pointer;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  color: #16bae7;
}

.co-dialog-countries .cities > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin: 10px 0;
}

.co-dialog-countries .error {
  font-family: "Paragraph";
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #e40521;
  padding: 0;
  margin: -12px 0;
}

@media screen and (max-width: 415px) {
  .co-dialog-countries {
    width: 85vw;
    overflow-x: hidden;
  }

  .co-dialog-countries .contents {
    width: 85vw;
    padding: 5% 3%;
  }

  .co-dialog-countries .imgs img {
    object-fit: cover;
    width: 100%;
    max-height: 100%;
  }
}
