.area-input input.k-input {
  width: 100%;
  height: 30px;
  font-family: "Paragraph";
  padding: 6px 4px 5px 9px;
  color: #424242;
}

.area-input input.k-input::placeholder {
  color: #666666;
}

.area-input span.k-input-inner {
  padding: 5px 6px 5px 8px;
  font-family: "Paragraph";
  color: #424242;
  font-size: 14px;
}
