.sidenav {
  width: max(24vw, 260px);
  padding: 32px 20px 46px 20px;
  background-color: var(--inchcape-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Link Modules */
.logo {
  padding: 0;
  margin-bottom: 70px;
}

.logo-img {
  width: min(100%, 200px);
  margin-bottom: 10px;
}

.logo-title {
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 600;
  font-size: max(1.2vw, 16px);
  line-height: 1;
  letter-spacing: 0.03em;
  cursor: pointer;
  text-decoration: none;
  color: var(--inchcape-dark);
  display: inline-block;
  width: auto;
  white-space: normal;
}

.sidebar-links,
.sidebar-top .item,
.sidebar-top .back {
  color: var(--inchcape-sidebar-texts);
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: max(0.8vw, 16px);
  line-height: 20px;
  padding: 12px;
}

.sidebar-top .item,
.sidebar-top .back {
  font-size: 14px;
  margin-bottom: 0;
}

.icons {
  color: #71839b;
  margin-right: 18px;
}

.sidebar-links:hover {
  color: var(--inchcape-sidebar-texts);
}

.sidebar-links.active {
  color: var(--inchcape-blue) !important;
  background-color: var(--inchcape-sidebar-hover);
  border-radius: 4px;
}

.sidebar-links.settings {
  display: none;
}

/* Profile and Logout Module */
.user-logout-module {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.user-profile {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 12px;
  user-select: none;
}

/* .user-profile:hover {
  background-color: var(--inchcape-sidebar-hover);
  border-radius: 4px;
} */

.user-profile.active {
  color: var(--inchcape-blue);
  background-color: var(--inchcape-sidebar-hover);
  border-radius: 4px;
}

.user {
  font-family: "Paragraph";
  font-style: normal;
  display: inline-block;
}

.user-name {
  font-weight: 700;
  line-height: 16px;
  font-size: max(0.6vw, 12px);
  color: var(--inchcape-sidebar-texts);
}

.user-email {
  font-size: 12px;
  line-height: 14px;
  color: #71839b;
}

.user-items {
  padding: 0;
}

.btn-icon {
  padding-right: 18px;
}

.logout-btn {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: max(0.8vw, 16px);
  line-height: 20px;
  border: none;
  background-color: inherit;
  color: var(--inchcape-sidebar-texts);
  text-align: start;
  padding: 12px;
}

.logout-btn:active,
.logout-btn:hover {
  background-color: var(--inchcape-sidebar-hover) !important;
  border-radius: 4px;
  color: var(--inchcape-blue) !important;
}

.logout-btn:hover,
.logout-btn:active,
.logout-btn.active > svg,
.logout-btn:active > svg,
.logout-btn:hover > svg,
.sidebar-links.active > svg {
  color: var(--inchcape-blue);
}

@media screen and (max-width: 767.9px) {
  .sidenav {
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    min-height: initial;
    box-shadow: none;
  }

  .sidenav .container {
    max-width: none;
    margin: 0;
  }

  .sidenav .mobile-cont {
    padding: 0.6rem 1rem;
    z-index: 101;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .navbar-toggler {
    border: none;
  }

  .sidenav .logo-img {
    width: min(50vw, 150px);
    margin-bottom: 0;
  }

  .navbar-toggler svg {
    color: var(--inchcape-dark);
    width: 32px;
    height: 32px;
    transform: scaleX(-1);
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .sidenav .logo {
    margin-bottom: 0;
  }

  .logo-title {
    display: none;
  }

  .navbar-collapse {
    border: none;
    max-height: 60vh;
    overflow: auto;
  }

  .sidebar-top,
  .user-logout-module {
    padding: 1rem;
  }

  .sidebar-links,
  .logout-btn {
    font-size: 14px;
  }

  .sidebar-links.settings {
    display: block;
  }
}
