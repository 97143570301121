/* Size of panel of admin-seat */
.admin-seat {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 45px;
  background: #ffffff;
}
.inputDate .k-daterangepicker {
  display: flex;
  justify-content: space-between;
  gap: 0;
}

.inputDate {
  padding: 0;
}

.inputDate label {
  width: 100%;
  margin: 0;
  padding: 0 1.5rem;
}
.admin-seat .roomNameImg > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

/* HEADER */
.seat-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #00233a;

  /* Inside auto layout */
}

.seatcapacity-title {
  font-size: 20px;
  font-weight: 700;
  font-family: "Title";
  font-style: normal;
  margin-bottom: 4.5px;
}

.floorlayout-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background-color: #00233a;
  width: 186px;
  height: 29px;
  color: #ffffff;
  padding: 5px 16px;
  border-radius: 4px 4px 0px 0px;
}

.floorlayout-btn > span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: auto;
  line-height: 10px;
}

.img-icon {
  width: 1.25em;
  height: 1.25em;
}

/* Modal Styling */
.modal-style {
  width: 55vw;
  max-width: 70vw;
}

.office-layout {
  width: 53vw;
  height: auto;
}

.modal-content {
  border-radius: 0;
  background-color: #f5f5f4;
}

.modal-header {
  border-bottom: none;
  padding: 15px 15px 0 0;
}

.modal-header .btn-close {
  padding: 0;
}

/* Styling for Seat Capacity */
/* For Date range picker */
.admin-seat .k-daterangepicker .k-dateinput {
  display: flex;
  flex-direction: row;
  width: 100%;
}
/* For time range oicker */
.admin-seat .k-input .k-input,
.k-input .k-picker,
.k-picker .k-input,
.k-picker .k-picker {
  border-width: 0 !important;
  color: inherit;
  background: #ffffff;
  font: inherit;
  outline: 0;
  box-shadow: none;
}
.admin-seat .Startdate {
  width: 68px;
  height: 20px;
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  /* $component-text */
  color: #424242;
  /* Inside auto layout */
}
.admin-seat .Enddate {
  width: 68px;
  height: 20px;
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  /* $component-text */
  color: #424242;
  /* Inside auto layout */
}
.lblStarttime {
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #424242;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.lblEndtime {
  font-family: "Sub title";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #424242;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
/* FOR SECOND DIV */
/* style for Label of room name */
.lblRoomname {
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #424242;
  /* Auto layout */
}
.selectSeats .k-multiselect {
  height: auto;
  min-height: 48px;
}

.roomName .k-multiselect {
  height: auto;
  min-height: 48px;
}
.roomName .k-input-values {
  display: flex;
  align-items: center;
}
.selectSeats .k-input-values {
  display: flex;
  align-items: center;
}

.selectSeats .k-chip-md {
  padding: 9px 9px !important;
}

.roomSeat svg {
  padding: 0;
}
/* Style for label of occupancy rule */
.lblOccupancy {
  padding: 0px 1px 0px 0px;
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #424242;
}

/* Style for label of select seacts */
.lblSelectseats {
  height: 0px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 1px 0px 0px;
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #424242;
}
.officedr {
  justify-content: space-between;
  align-items: center;
  padding: 0px 1px 0px 0px;
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #424242;
}

.lblReason {
  height: 0px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 1px 0px 0px;
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #424242;
  flex-direction: column;
  gap: 5px;

}
.writeTheReson {
height: 50px;
}

.lockOffice {
  color:#424242;
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.lockOffice .k-checkbox:checked,
.lockOffice .k-checkbox:checked:focus,
.lockOffice .k-checkbox.k-checked.k-focus  {
  color: #fff !important;
  border-color: rgb(22, 186, 231) !important;
  background-color: #16bae7 !important;
  margin: 5px 0px;
  box-shadow: 0 0 0 2px rgb(22 186 231 / 30%) !important
}

.admin-seat .k-column-title {
  height: 34px;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  /* Primary Inchcape/60% */
  color: rgba(0, 35, 58, 0.6);
  min-width: 0;
  font-family: "Title";
  white-space: initial;
  flex-shrink: 1;
  overflow: hidden;
}
/* Style for label available seats */
.lblAvailableseats {
  width: 210px;
  font-family: "Sub Title";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 167% */
  /* $primary */
  color: #16bae7;
}
.seathistory .k-grid-pager {
  font-family: "Paragraph";
}
.Cancel {
  box-sizing: border-box;
  /* Auto layout */
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  gap: 4px;
  width: 128px;
  height: 30px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 2e-6) 0%,
      rgba(0, 0, 0, 0.02) 100%
    ),
    #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  /* For color of text */
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: center;
  /* Buttons/$button-text */
  color: #424242;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.Cancel:hover {
  color: #424242;
  background: #e4e4e4;
  transition: all 0.3s ease-in-out;
}

.Set {
  /* _Base Button / Medium */
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  gap: 4px;
  width: 128px;
  height: 30px;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: center;
  /* $component-text-white */
  color: #ffffff;
  /* $component-text-white */
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.04) 99.48%,
      rgba(0, 0, 0, 0.04) 99.49%
    ),
    #16bae7;
  /* Buttons/Primary/Border/$primary-button-border
 primary solid button - normal/focused border; slider handle - normal border; floating action button (FAB) - normal/focused border
 */

  border: 1px solid #16bae7;
  border-radius: 4px;
  /* Inside auto layout */
  flex: none;
  order: 0;
}
.Set:hover {
  color: var(--inchcape-white);
  background: #049ec9;
  transition: all 0.3s ease-in-out;
}
.Set:disabled {
  cursor: default;
  opacity: 0.7;
  background-color: var(--inchcape-blue);
}

/* For last div */
.SeatCapacityHistory {
  padding: 10px 0px 41px 0px;
  width: 100%;
}

.SeatCapacityHistory .k-grid-content {
  overflow-y: hidden;
}

.SeatCapacityHistory > div {
  width: 100%;
}
/* for table */
.seathistory {
  display: flex;
  padding: 40px 0px 0px 5px;
}
.seathistory .k-grid {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
/* for history */
.seatcapacityhistory-title {
  width: 100% !important;
  font-size: 20px;
  font-weight: 700;
  font-family: "Paragraph";
  font-style: normal;
  border-bottom: 2px solid #00233a;
  padding-bottom: 10px;
  margin: 0;
}
.admin-seat .k-widget,
.admin-seat .k-grid {
  border: none !important;
}

.admin-seat .k-grid-header {
  font-family: "Title";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  padding: 15px;
  color: rgba(0, 35, 58, 0.6) !important;
}

.admin-seat .k-header > .k-cell-inner {
  margin: 0px 0px !important;
}

.admin-seat .k-header,
.admin-seat .k-header {
  border: none !important;
  border-bottom: 1px solid #ebeff2 !important;
}

.admin-seat .k-cell-inner {
  justify-content: center !important;
  margin: 0;
  padding: 0;
}

.admin-seat .k-cell-inner > .k-link {
  padding: 0px !important;
}

.admin-seat .k-link {
  cursor: normal !important;
}

.admin-seat .k-master-row,
.admin-seat .k-alt {
  border-right: none !important;
}

.admin-seat .k-grid tbody {
  text-align: center !important;
}

.admin-seat .k-grid th {
  padding: 15px 6px !important;
}

.admin-seat .k-table-row > div {
  height: 100%;
  width: 10%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-seat .k-grid td {
  border: none !important;
  padding: 15px 22px !important;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  color: rgba(0, 35, 58, 0.9) !important;
}
.active-status {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #37b400;
}
.cancel-btn1 {
  cursor: pointer;
  width: 2rem;
  color: #e40521 !important;
}
/* Pagination */
.admin-seat .k-grid-pager {
  /* flex-direction: row-reverse !important; */
  justify-content: center;
}

.admin-seat .k-pager-sizes {
  flex-direction: row-reverse !important;
  gap: 20px;
}
.admin-seat .k-pager-md .k-pager-info,
.k-pager-sm .k-pager-info,
.k-pager-sm .k-pager-sizes,
.k-pager-sm .k-pager-numbers {
  display: flex;
}
.admin-seat .k-pager-numbers .k-selected {
  color: #16bae7 !important;
}

.admin-seat .k-button-flat-primary {
  color: rgba(0, 35, 58, 0.5) !important;
}

.admin-seat .k-pager-sizes .k-dropdownlist {
  border: none !important;
  background-color: #ffffff !important;
}

.admin-seat .k-picker-solid:focus-within {
  box-shadow: none !important;
}

.admin-seat .k-pager-numbers .k-link.k-selected {
  background-color: #ffffff !important;
  color: #16bae7 !important;
  border: 1px #ffffff !important;
}

.admin-seat .k-pager-numbers .k-link:hover {
  background-color: #ffffff !important;
}

.admin-seat .k-pager-numbers .k-link:focus {
  box-shadow: none !important;
  color: #16bae7 !important;
}

.admin-seat .k-pager-numbers .k-link {
  color: rgba(0, 35, 58, 0.5) !important;
  cursor: pointer !important;
}

.admin-seat .k-pager-nav.k-link:focus {
  background-color: #ffffff !important;
  box-shadow: none !important;
}

.admin-seat .k-pager-nav.k-link:hover {
  background-color: rgba(22, 186, 231, 0.3) !important;
  box-shadow: none !important;
  border-radius: 50px;
}
.Add3 {
  color: #16bae7;
  width: 25px;
  height: 25px;
}

/* for history */
.seatcapacityhistory-title {
  font-size: 20px;
  font-weight: 700;
  font-family: "Title";
  font-style: normal;
  border-bottom: 1px solid #00233a;
  padding-bottom: 10px;
  margin: 0;
}
.admin-seat .k-widget,
.admin-seat .k-grid {
  border: none !important;
  width: 100%;
}

.admin-seat .k-grid-header {
  font-family: "Title";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  padding: 0;
  color: rgba(0, 35, 58, 0.6) !important;
}

.admin-seat .k-grid tr {
  background: #fff;
  position: relative;
}

.admin-seat .k-header > .k-cell-inner {
  margin: 0px 0px !important;
}

.admin-seat .k-header,
.admin-seat .k-header {
  border: none !important;
  border-bottom: 1px solid #ebeff2 !important;
}

.admin-seat .k-cell-inner {
  justify-content: center !important;
  margin: 0;
  padding: 0;
}

.admin-seat .k-cell-inner > .k-link {
  padding: 0px !important;
}

.admin-seat .k-link {
  cursor: normal !important;
}

.admin-seat .k-master-row,
.admin-seat .k-alt {
  border-right: none !important;
}

.admin-seat .k-grid tbody {
  text-align: center !important;
}

.admin-seat .k-grid th {
  padding: 15px 6px !important;
}

.admin-seat .k-grid td {
  border: none !important;
  padding: 15px 22px !important;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  color: rgba(0, 35, 58, 0.9) !important;
  background-color: #fff;
  border: none;
}
.admin-seat .k-pager-numbers .k-link.k-selected .k-button-text {
  color: var(--inchcape-blue) !important;
}
.admin-seat select.k-dropdown {
  display: none !important;
}
.admin-seat .k-button-flat-primary {
  color: rgba(0, 35, 58, 0.5) !important;
  border: none !important;
}

.admin-seat .k-grid-pager {
  border: none !important;
}
.admin-seat .k-grid th {
  border-width: 0 0 1px 0 !important;
}
.admin-seat.k-pager-wrap,
.admin-seat .k-pager {
  border-color: rgba(0, 0, 0, 0.08);
  color: #212529;
  background-color: #ffffff;
}
.admin-seat .k-pager-numbers {
  display: block;
}
.admin-seat .k-grid-th {
  border-width: 0 0 1px 0;
}
.active-status {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #37b400;
}
.cancel-btn1 {
  cursor: pointer;
  width: 2rem;
  color: #e40521 !important;
}
/* Pagination */
.admin-seat .k-grid-pager {
  /* flex-direction: row-reverse !important; */
  justify-content: center;
}

.admin-seat .k-pager-sizes {
  flex-direction: row-reverse !important;
  gap: 20px;
}

.admin-seat .k-pager-numbers .k-selected {
  color: #16bae7 !important;
}

.admin-seat .k-button-flat-primary {
  color: rgba(0, 35, 58, 0.5) !important;
}

.admin-seat .k-pager-sizes .k-dropdownlist {
  border: none !important;
  background-color: #ffffff !important;
}

.admin-seat .k-picker-solid:focus-within {
  box-shadow: none !important;
}

.admin-seat .k-pager-numbers .k-link.k-selected {
  background-color: #ffffff !important;
  color: #16bae7 !important;
  border: 1px #ffffff !important;
}

.admin-seat .k-pager-numbers .k-link:hover {
  background-color: #ffffff !important;
}

.admin-seat .k-pager-numbers .k-link:focus {
  box-shadow: none !important;
  color: #16bae7 !important;
}

.admin-seat .k-pager-numbers .k-link {
  color: rgba(0, 35, 58, 0.5) !important;
  cursor: pointer !important;
}

.admin-seat .k-pager-nav.k-link:focus {
  background-color: #ffffff !important;
  box-shadow: none !important;
}
.admin-seat .k-notification-success {
  display: flex;
  gap: 4px;
  height: 27px;
  position: fixed;
  font-family: "Sub Title";
  top: 8vh;
  right: 5vw;
  border-color: #37b400;
  color: white;
  background-color: #37b400;
}
.admin-seat .k-pager-nav.k-link:hover {
  background-color: rgba(22, 186, 231, 0.3) !important;
  box-shadow: none !important;
  border-radius: 50px;
}

.admin-seat .k-table {
  width: 100% !important;
}

.admin-seat .k-grid th,
.admin-seat .k-grid td {
  text-overflow: initial;
  white-space: normal;
  word-break: break-word;
}

@media screen and (max-width: 991.9px) {
  .admin-seat .seatcapacity-title {
    padding-bottom: 0;
    font-size: min(7vw, 20px);
  }

  .floorlayout-btn {
    padding: 5px 8px;
  }

  .floorlayout-btn,
  .floorlayout-popout {
    height: auto;
    width: min(35vw, 186px);
    gap: 8px;
    flex-wrap: wrap;
  }

  .floorlayout-btn svg {
    width: 16px;
    height: 16px;
  }

  .floorlayout-btn > span {
    width: auto;
  }

  .admin-seat .row {
    padding: 0;
    margin: 0;
  }

  .admin-seat .row > *:not(.inputDate):not(.lblAvailableseats),
  .admin-seat .inputDate .k-daterangepicker label {
    padding: 0 5px !important;
  }

  .lblAvailableseats {
    padding: 8px 5px 0 5px !important;
  }

  .admin-seat .Cancel,
  .admin-seat .Set {
    flex: 1;
  }

  .seatcapacityhistory-title {
    padding-bottom: 10px;
  }

  .admin-seat .mobile-table .line {
    width: 105%;
    gap: 10px;
    grid-template-columns: minmax(1fr, 120px) 1fr;
  }

  .admin-seat .mobile-table .top > div,
  .admin-seat .mobile-table .bot > div {
    gap: 18px;
  }

  .admin-seat .mobile-table .line svg {
    color: #e40521;
  }

  .admin-seat .mobile-table .bot > div {
    padding-top: 0;
  }

  .admin-seat .mobile-table label {
    font-family: "Title";
    color: rgba(0, 35, 58, 0.6);
  }

  .admin-seat .mobile-table p {
    max-width: 100%;
    font-family: "Sub Title";
    font-size: 14px;
    color: rgba(0, 35, 58, 0.9);
    word-break: break-all;
  }
}

.admin-seat .overlay {
  grid-area: 1 / 1 / -2 / -2;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.77);
  user-select: none;
}

.admin-seat .overlay .k-loader,
.admin-seat .overlay p {
  color: var(--inchcape-blue);
  font-size: 24px;
  font-weight: 700;
  font-family: "Paragraph";
}