/* whole dialog styling */
.reject-dialog div.k-dialog {
  padding: 41px 51px;
  width: 301px;
  height: auto;
  border: 1px;
  border-radius: 10px;
}

/* dialog contents */
.reject-dialog div.k-dialog-content {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
}

/* input */
.reason-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 4px;
  padding: 5px 4px 5px 9px;
  justify-content: center;
  align-items: center;
  font-family: "Paragraph";
  flex: 1 0 0;
  align-self: stretch;
}

.reject-dialog .k-label {
  padding-left: 4.6%;
  font-family: "Paragraph";
  font-size: 12px;
  line-height: 20px;
  color: #666666;
  white-space: noWrap;
}

.reject-dialog .k-input {
  width: 100%;
}

/* dialog title */
.reject-dialog .title {
  font-family: "Title";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  gap: 22px;
  justify-content: center;
  align-items: center;
  width: 80%;
  text-align: center;
  margin-top: 25px;
  color: #00233A;
  line-height: 29.5px;
}

/* btn */

.reject-dialog .close-btn-container {
  text-align: right;
  width: 100%;
  margin-bottom: -30px;
}

.reject-dialog .close-btn-container>button {
  background-color: transparent;
  border: 0;
}

.reject-dialog .buttons {
  display: flex;
  gap: 20px;
}

.reject-dialog .Nobtn {
  box-sizing: border-box;
  font-family: "Paragraph";
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  gap: 4px;
  width: 128px;
  height: 30px;
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 2e-6) 0%,
      rgba(0, 0, 0, 0.02) 100%),
    #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.reject-dialog .Nobtn:hover {
  color: #424242;
  background: #e4e4e4;
  transition: all 0.3s ease-in-out;
}

.reject-dialog .Yesbtn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  gap: 4px;
  width: 128px;
  height: 30px;
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.04) 99.48%,
      rgba(0, 0, 0, 0.04) 99.49%),
    #16bae7;
  border: 1px solid #16bae7;
  border-radius: 4px;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.reject-dialog .Yesbtn:hover {
  color: var(--inchcape-white);
  background: #049ec9;
  transition: all 0.3s ease-in-out;
}

.reject-dialog .Yesbtn:active {
  background: var(--inchcape-blue);
  color: var(--inchcape-white);
  border-color: var(--inchcape-blue);
}