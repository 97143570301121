.reminder-dialog {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.reminder-dialog .overlay {
  grid-area: 1 / 1 / -2 / -2;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.77);
  user-select: none;
}

.reminder-dialog .overlay .k-loader,
.reminder-dialog .overlay p {
  color: var(--inchcape-blue);
  font-size: 20px;
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-family: "Paragraph";
}

.reminder-dialog .content {
  grid-area: 1 / 1 / -2 / -2;
  display: flex;
  flex-direction: column;
  max-width: 433px;
  padding: 48px 65px;
  align-items: center;
  justify-content: center;
  gap: 47px;
}

.reminder-dialog > * {
  width: 100%;
}

.reminder-dialog h5 {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  color: var(--inchcape-blue);
  font-family: "Title";
}

.reminder-dialog .link {
  font-size: 12px;
}

.reminder-dialog .display {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 92px;
}

.reminder-dialog .k-label {
  margin-left: 4.6%;
  color: #666666;
  margin-bottom: 5px;
  font-size: 12px;
  font-family: "Paragraph";
}

.reminder-dialog p {
  padding: 0;
}

.reminder-dialog p,
.reminder-dialog .k-input {
  color: #666666;
  font-size: 14px;
  font-family: "Paragraph";
}

.reminder-dialog span.k-switch {
  font-size: 10px;
}

.reminder-dialog .k-switch-label-on,
.reminder-dialog .k-switch-label-off {
  font-family: "Paragraph";
  padding: 3px;
}

.reminder-dialog span.k-switch-on .k-switch-track {
  border: var(--inchcape-blue);
  background-color: var(--inchcape-blue);
  height: 24px;
}

.reminder-dialog span.k-switch-on:focus .k-switch-track,
.reminder-dialog .k-switch-on.k-focus .k-switch-track {
  outline: 2px solid var(--inchcape-blue);
}

.reminder-dialog span.k-switch-off .k-switch-track {
  height: 24px;
}

.reminder-dialog span.k-switch-md .k-switch-thumb {
  height: 22px;
  width: 27px;
}

.reminder-dialog .form-buttons {
  display: flex;
  gap: 8px;
  box-sizing: border-box;
  border: none;
  justify-content: center;
}

.reminder-dialog .save,
.reminder-dialog .cancel {
  width: 128px;
  height: 30px;
  align-items: center;
  border-radius: 4px;
  border: none;
  font-family: "Paragraph";
}

.reminder-dialog .save {
  background-color: var(--inchcape-blue);
  color: #ffffff;
  border: 1px solid var(--inchcape-blue);
}

.reminder-dialog .save:hover {
  color: var(--inchcape-white);
  background: #049ec9;
  transition: all 0.3s ease-in-out;
}

.reminder-dialog .cancel {
  background-color: #f5f5f5;
  color: var(--inchcape-label);
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.reminder-dialog .cancel:hover {
  color: #424242;
  background: #e4e4e4;
  transition: all 0.3s ease-in-out;
}

.reminder-dialog .error {
  color: var(--inchcape-red);
  font-family: "Paragraph";
  text-align: center;
  margin-bottom: 8px;
  padding: 0;
}

@media screen and (max-width: 480px) {
  .reminder-dialog {
    width: 85vw;
    overflow-x: hidden;
  }

  .reminder-dialog .content {
    width: 85vw;
    padding: 5% 3%;
  }

  .reminder-dialog .link {
    width: 100%;
  }

  .reminder-dialog .display {
    gap: 20px;
    width: 100%;
  }

  .reminder-dialog .display p {
    margin-bottom: 0;
  }

  .reminder-dialog .content div:last-child {
    width: 100%;
  }

  .reminder-dialog .form-buttons {
    width: 100%;
  }
}
