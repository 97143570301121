/* Size of panel of approval-management */
.approval-management {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.inp {
  align-self: flex-end;
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px 5px 0px;
  width: 237px;
  height: 30px;
  background: #ffffff;
  gap: 4px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.approval-management .k-table,
.approval-management .k-grid-table {
  width: 100% !important;
}

.inp > input {
  width: 204px;
  height: 20px;
  border: none;
  outline: none;
  font-family: "Paragraph";
  font-size: 14px;
  color: #666666;
}

.approval-management .k-grid-content {
  overflow-y: hidden;
}

.approval-management .k-column-title {
  min-width: 0;
  text-overflow: ellipsis;
  font-family: "Title";
  color: rgba(0, 35, 58, 0.6);
  text-align: center;
  white-space: normal;
  flex-shrink: 1;
  overflow: hidden;
}

.approval-management-title {
  font-size: 16px;
  font-weight: 700;
  font-family: "Title";
  font-style: normal;
  margin-bottom: 4.5px;
  letter-spacing: 0.16px;
  line-height: 10px;
}

.approval-management .k-grid-header {
  padding-right: 0;
}

.approval-management .k-grid-header-wrap {
  border: 0;
}
.approval-management .k-table-md .k-table-td,
.k-table-md .k-table-th {
  padding: 10px;
}
.approval-management th.k-header label.k-checkbox-label {
  font-size: 14px;
  font-weight: 600px;
  font-family: "Title";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  /* Primary Inchcape/60% */
  color: rgba(0, 35, 58, 0.6);
  text-align: center;
}
.approval-management .k-grid td {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  /* Primary Inchcape/90% */
  color: rgba(0, 35, 58, 0.9);
  background-color: #fff;
  border: none;
  text-overflow: initial;
  white-space: initial;
  word-break: break-word;
}
.approval-management .k-grid th {
  border-width: 0 0 1px 0 !important;
  text-align: center;
}
.del {
  height: 10px;
  width: 10px;
}
.approval-management .k-cell-inner {
  justify-content: center;
}
.approval-management .k-grid-content {
  overflow-y: hidden;
}
.approval-management .k-grid-header {
  padding-right: 0;
  margin-bottom: 10px;
}
.approval-management .k-grid-header-wrap {
  border: 0;
}
.tbl {
  width: 100%;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
}

.tbl div.k-grid {
  margin-top: 0;
  border: none;
  width: 100%;
}
.history-rep {
  margin-top: 0 !important;
}

/* change row color if selected */
.approval-management .k-grid td.k-selected,
.approval-management .k-grid tr.k-selected > td {
  background-color: #16bae755;
}

.approval-management .k-pager-numbers .k-selected .k-button-text {
  color: var(--inchcape-blue) !important;
}
.approval-management select.k-dropdown {
  display: none !important;
}
.approval-management .k-table-thead {
  background-color: #fff;
}
.approval-management .k-button-flat-primary {
  color: rgba(0, 35, 58, 0.5) !important;
}

.approval-management .k-grid-pager {
  border: none !important;
}

.approval-management .k-grid th {
  text-overflow: initial;
  white-space: initial;
  word-break: break-all;
}

.approval-management .k-grid th:last-child {
  word-break: normal;
}

.approval-management .k-grid td:first-child {
  text-overflow: clip;
}

.approval-management.k-pager-wrap,
.approval-management .k-pager {
  border-color: rgba(0, 0, 0, 0.08);
  color: #212529;
  background-color: #ffffff;
}
.approval-management .k-pager-numbers {
  display: block;
}
.approval-management .k-pager-numbers .k-link:focus {
  box-shadow: none !important;
}
.approval-management .k-pager-numbers .k-link:hover {
  background-color: #fff !important;
  color: #16bae7;
}
.approval-management .k-pager-numbers .k-link {
  color: #424242;
}
.approval-management
  .history-module
  .k-pager-numbers
  .k-selected
  .k-button-text {
  color: var(--inchcape-blue) !important;
  /* background-color: #fff !important; */
}
.approval-management ul.pagination {
  height: 26px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.admin .k-notification-success {
  display: flex;
  gap: 4px;
  height: 27px;
  position: fixed;
  top: 8vh;
  font-family: "Sub Title";
  right: 5vw;
  border-color: #37b400;
  color: white;
  background-color: #37b400;
}
.approval-management .set-inp {
  display: flex;
  justify-content: space-between;
}
.approval-management .btn:disabled,
.approval-management .btn.disabled {
  background: #16bae7;
  border: 1px solid #16bae7;
  color: #fff;
}

.approval-management .k-button-flat.k-selected {
  color: #16bae7 !important;
}

.approval-management .k-table-tbody .k-table-row.k-selected,
.approval-management .k-table-list .k-table-row.k-selected {
  background-color: transparent;
}

/* .approval-management td {
  min-width: 50px;
  text-overflow: ellipsis;
} */

@media screen and (max-width: 991.9px) {
  .approval-management .mobile-table {
    border: none;
    margin-bottom: 4rem;
  }

  .approval-management .mobile-table .top {
    border: 2px solid #16bae7;
  }

  .approval-management .mobile-table .bot {
    border: 1px solid #414141;
  }

  .approval-management .mobile-table .line {
    width: 105%;
    gap: 10px;
    grid-template-columns: minmax(1fr, 120px) 1fr;
  }
  .approval-management .mobile-table .k-checkbox-md {
    width: 30px;
    height: 30px;
    /* margin: 12px 0 20px; */
    display: flex;
    flex-direction: column;
    bottom: 10px;
  }
  .approval-management .mobile-table .top > div,
  .approval-management .mobile-table .bot > div {
    gap: 5px;
  }

  .approval-management .mobile-table label {
    font-family: "Title";
    color: rgba(0, 35, 58, 0.6);
    text-align: start;
    font-size: 14px;
  }

  .approval-management .mobile-table p {
    max-width: 100%;
    font-family: "Sub Title";
    font-size: 13px;
    word-break: break-all;
    color: rgba(0, 35, 58, 0.9);
    text-align: start;
  }
}

@media screen and (max-width: 767.9px) {
  .approval-management {
    width: 100%;
  }

  .approval-management .tbl {
    width: 100%;
  }

  .approval-management .set-inp {
    flex-direction: column;
    gap: 11px;
  }

  .approval-management .inp {
    width: 100%;
    order: -1;
  }
}

.k-switch-on .k-switch-track {
  border-color: #16bae7;
  background-color: #16bae7;
}

.k-grid tr.k-alt {
  background-color: transparent;
}

.k-grid tbody > tr:not(.k-detail-row):hover {
  background-color: transparent;
}
