.co-list > div {
  cursor: pointer;
  padding: 36px min(3vw, 40px);
  border: 1px solid var(--inchcape-blue);
  font-family: "Paragraph";
}

.co-list svg {
  width: 37px;
  height: 37px;
}

.co-list > div > p {
  height: 20px;
  font-size: max(0.9vw, 16px);
  line-height: 20px;
  padding: 0;
  margin: 0;
}

.co-list svg,
.co-list > div > p {
  color: var(--inchcape-blue);
}

.co-contents {
  width: 100%;
  border: 1px solid var(--inchcape-blue);
}

.block-contents {
  display: grid;
  padding: min(3.5vw, 55px) min(4vw, 81px);
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  column-gap: 26px;
  row-gap: 30px;
  height: 100%;
  justify-items: center;
}

.co-contents .info {
  user-select: none;
  padding: 1rem;
}

.co-contents .info p {
  font-family: "Paragraph";
  font-size: max(0.8vw, 14px);
}

.co-contents .info svg {
  color: var(--inchcape-dark);
  border: 1px solid var(--inchcape-dark);
  border-radius: 50%;
  flex: 0 0 auto;
}

.co-list .selected {
  background: var(--inchcape-blue);
  font-weight: 700;
}

.co-list .selected p,
.co-list .selected svg {
  color: var(--inchcape-white);
}

.co-square {
  display: flex;
  gap: 10px;
  width: 100%;
  height: 110px;
  align-items: center;
  justify-content: center;
  background: #ebeef2;
  padding: 42px 24px;
}

.co-square button {
  border: none;
  padding: 0;
  margin: 0;
  background: none;
}

.co-square > p {
  margin: 0;
  text-align: center;
  font-family: "Title";
  font-weight: 600;
  font-size: max(0.73vw, 16px);
  line-height: 20px;
  color: var(--inchcape-sidebar-texts);
  padding: 0;
}

.co-square > svg {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  flex-shrink: 0;
  cursor: pointer;
}

.co-offices {
  align-items: flex-end;
  padding: 20px 24px;
  box-shadow: inset 0 90px 20px 0 rgba(150, 150, 150, 0.2),
    inset 0 -25px 40px 2px #00233a;
}

.co-offices p {
  color: #ffffff;
}

.co-offices svg {
  color: #ffffff;
}

.block-contents > button {
  font-family: "Paragraph";
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--inchcape-blue);
  box-sizing: border-box;
  background: none;
  width: 100%;
  border: 1px dashed var(--inchcape-blue);
  height: 110px;
}

.admin-countries-offices div.k-notification-success,
.admin-countries-offices div.k-notification-error {
  display: flex;
  gap: 4px;
  position: fixed;
  font-family: "Sub Title";
  top: 10vh;
  right: 5vw;
}

.reminder .k-animation-container-relative {
  position: fixed;
}

@media screen and (max-width: 991px) {
  .co-list,
  .co-list > div {
    width: 100%;
  }

  .co-list > div {
    padding-top: max(2vw, 1rem);
    padding-bottom: max(2vw, 1rem);
  }
}

@media screen and (max-width: 767px) {
  .block-contents {
    display: flex;
    flex-direction: column;
    padding: 30px 33px;
  }

  .admin-countries-offices .co-list > div {
    gap: 5%;
    flex: 1;
    padding: 0.5rem;
    gap: 3px !important;
  }

  .co-list svg {
    width: min(8vw, 37px);
    height: min(8vw, 37px);
  }

  .co-square,
  .co-contents button:last-child {
    width: 100%;
    height: 75px;
  }

  .co-offices {
    align-items: center;
    box-shadow: inset 0 50px 10px 0 rgba(150, 150, 150, 0.2),
      inset 0 -20px 30px 2px #00233a;
  }

  .co-offices svg {
    margin-bottom: 4px;
  }
}
