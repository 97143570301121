/* Dropdown Styling */
.k-input-value-text,
.k-list-item {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: var(--inchcape-label);
}

.k-picker-solid,
.k-input-md,
.k-picker-md {
  /* height: 30px; */
  background: var(--inchcape-white);
}

.k-list-item:hover,
.k-list-optionlabel:hover,
.k-list-item.k-hover,
.k-hover.k-list-optionlabel,
.k-list-item.k-selected,
.k-selected.k-list-optionlabel,
.k-list-item.k-selected:hover,
.k-radio:checked,
.k-radio:checked:focus {
  color: var(--inchcape-white);
  background-color: var(--inchcape-blue);
}

.k-list-item:focus,
.k-list-optionlabel:focus,
.k-list-item.k-focus,
.k-focus.k-list-optionlabel,
.k-radio:checked,
.k-radio:checked:focus {
  box-shadow: none;
}

/* Kendo DateRange Picker Styles*/
.k-calendar,
.k-dateinput {
  font-family: "Paragraph";
}

.k-calendar .k-calendar-view .k-today {
  color: var(--inchcape-blue);
}

.k-calendar .k-calendar-nav-today:hover {
  color: var(--inchcape-blue);
  background-color: rgba(22, 186, 231, 0.3) !important;
}

.k-calendar .k-nav-today,
.k-calendar .k-calendar-nav-today {
  color: var(--inchcape-blue);
}

.k-calendar .k-range-start,
.k-calendar .k-range-end,
.k-calendar .k-range-mid {
  background-color: rgba(22, 186, 231, 0.3);
}

.k-calendar .k-range-start .k-calendar-cell-inner,
.k-calendar .k-range-start .k-link,
.k-calendar .k-range-end .k-calendar-cell-inner,
.k-calendar .k-range-end .k-link {
  background-color: var(--inchcape-blue) !important;
}

.k-calendar .k-calendar-td:hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td:hover .k-link,
.k-calendar .k-calendar-td.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-hover .k-link {
  border-color: var(--inchcape-blue);
  box-shadow: none;
  color: var(--inchcape-label) !important;
  background-color: rgba(22, 186, 231, 0.3) !important;
}

.k-calendar .k-range-split-start::after {
  left: -5px;
  right: auto;
  background-image: linear-gradient(
    to left,
    rgba(22, 186, 231, 0.25),
    transparent 100%
  );
}

.k-calendar .k-range-split-end::after {
  right: -5px;
  left: auto;
  background-image: linear-gradient(
    to right,
    rgba(22, 186, 231, 0.25),
    transparent 100%
  );
}

.k-input-solid.k-invalid {
  border-color: rgba(0, 0, 0, 0.16);
}

.k-weekend {
  opacity: 0.6;
  pointer-events: none;
}

/* Kendo TimePicker Styles */
.k-floating-label-container > .k-label {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: var(--inchcape-label);
}

.k-timeselector {
  font-family: "Paragraph";
}

.k-input .k-input {
  font-family: "Paragraph";
  color: var(--inchcape-label);
}
.k-time-header .k-time-now,
.k-time-selector-header .k-time-now {
  color: var(--inchcape-blue) !important;
}

.k-time-list .k-item:hover {
  color: var(--inchcape-blue);
}

.k-floating-label-container > .k-label {
  transition: none;
}

.k-button-solid-primary {
  border-color: var(--inchcape-blue);
  color: var(--inchcape-white);
  background-color: var(--inchcape-blue);
}

.k-button-solid-primary:hover,
.k-button-solid-primary.k-hover {
  border-color: var(--inchcape-blue);
  background-color: var(--inchcape-blue);
  opacity: 0.7;
}

.k-button-solid-primary:focus,
.k-button-solid-primary.k-focus {
  box-shadow: 0 0 0px 2px var(--inchcape-sidebar-hover);
}

/* Tooltips */
.k-tooltip {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  border-color: var(--inchcape-purple);
  color: var(--inchcape-white);
  background-color: var(--inchcape-purple);
}

.k-tooltip .k-callout {
  color: var(--inchcape-purple);
}

/* Textbox & Textarea */
.k-input-inner {
  font-family: "Paragraph";
}
