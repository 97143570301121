.first-login {
  width: 100%;
  background: url("../../img/validate-bg.png") no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0;
}

.first-login .content {
  width: 360px;
  /* width: 568px; FOR CHILE*/ 
}

.first-login .content,
.first-login .subcont {
  gap: 30px;
}

.first-login label {
  color: var(--inchcape-white);
  font-size: 1.5rem;
  font-family: "Paragraph";
}

.first-login .next-btn {
  padding: 5px 23px;
  background: var(--inchcape-blue);
  font-size: max(1.3vw, 20px);
  font-family: "Paragraph";
}

.first-login .next-btn:hover,
.first-login .next-btn:active {
  background: var(--inchcape-blue) !important;
  border-color: var(--inchcape-blue) !important;
}

.first-login .next-btn:disabled,
.first-login .next-btn.disabled,
.first-login fieldset:disabled .next-btn {
  background: var(--inchcape-blue);
  border-color: var(--inchcape-blue);
}
.first-login .contenFlags{
  flex-direction: row;
}

@media screen and (max-width: 490px) {
  .first-login .content {
    width: 100%;
  }
  .first-login .contenFlags{
    flex-direction: column;
  }
}

