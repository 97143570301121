.areas-table {
  width: 100%;
  padding: 0;
}

.areas-table div.k-grid {
  border: none;
  filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
  padding: 10px;
}

.areas-table div.k-grid-header {
  padding-right: 0;
  border-bottom: 1px solid #ebeff2;
  font-family: "Sub Title";
  font-size: 14px;
  letter-spacing: 0.01em;
  color: rgba(0, 35, 58, 0.6);
  padding-bottom: 8px;
}

.areas-table .deleteIcon,
.areas-table .editIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.areas-table .deleteIcon {
  color: #e40521;
}
.mobile-table p {
font-family: "Paragraph"
  
  }
  
.areas-table .editIcon {
  color: #16bae7;
}

.areas-table .k-grid table {
  padding-right: 0;
}

.areas-table .k-grid-content {
  overflow: auto;
}

.areas-table .k-grid-header,
.areas-table .k-header,
.areas-table .k-grid-header-wrap,
.areas-table td {
  border: none;
  background: none;
}

.areas-table .k-cell-inner {
  justify-content: center;
}

.areas-table .k-grid td {
  text-align: center;
  border: none;
  color: rgba(0, 35, 58, 0.9);
  font-family: "Paragraph";
  font-size: 13px;
  letter-spacing: 0.01em;
  padding: 15px;
  text-overflow: initial;
  white-space: initial;
}

.areas-table .k-link,
.k-link:hover {
  cursor: default !important;
}

.areas-table .k-grid tr.k-alt,
.areas-table .k-grid tr {
  background: #ffffff;
}

.areas-table .k-grid tbody > tr:not(.k-detail-row):hover,
.areas-table .k-grid tbody > tr:not(.k-detail-row).k-hover,
.areas-table .k-grid tr.k-alt:hover,
.areas-table .k-grid tr.k-alt.k-hover {
  background: #16bae755 !important;
}

.areas-table .mobile-table {
  border: none;
}

.areas-table .mobile-table .top > div,
.areas-table .mobile-table .bot > div {
  padding: 15px 16px;
  gap: 24px;
}

.areas-table .mobile-table .line {
  grid-template-columns: 1.8fr 2fr;
}

.areas-table .mobile-table label {
  font-family: "Title";
  letter-spacing: 0.01em;
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 35, 58, 0.6);
}

.areas-table .mobile-table .bot,
.areas-table .mobile-table .top {
  border: 1px solid #b8b8b8;
}
