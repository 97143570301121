/* whole dialog styling */
.sec-data div.k-dialog {
  padding: 41px 51px;
  width: 500px;
  height: auto;
}

/* dialog contents */
.sec-data div.k-dialog-content {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 57px;
}

/* dialog title */
.sec-data .title {
  display: flex;
  gap: 22px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sec-data .title > svg {
  color: #00233a;
  width: 23px;
  height: 23px;
}

.sec-data h3 {
  margin: 0;
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 20px;
  color: #00233a;
}

.info {
  width: 20px;
  height: 20px;
}

.information {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.infotext {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: rgba(0, 35, 58, 0.9);
}

.area-type .k-multiselect {
  width: 60%;
}

.sec-data .startDate {
  width: 68px;
  height: 20px;
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #424242;
}

.sec-data .endDate {
  width: 68px;
  height: 20px;
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #424242;
}

.sec-data span.k-datepicker {
  width: 306px;
  height: 30px;
}

.sec-data .date-range .k-dateinput {
  width: 100%;
}

/* individual and room parent div*/
.sec-data .area-type {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

/* both dropdownlist component */
.sec-data .area-type span.k-dropdownlist {
  width: 115px;
  height: 30px;
}

/* all add logo styles*/
.sec-data .logo-add {
  width: 18px;
  height: 18px;
}

.sec-data .k-daterangepicker {
  gap: 13px;
}

.sec-data .area-type .individual {
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #424242;
}

.sec-data .area-type .Room-type {
  font-family: "Sub Title";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #424242;
}

.area-type .k-input-values {
  display: flex;
}

.area-type .k-chip-label {
  font-family: "Paragraph";
}

/*group button styles*/
.sec-data div.k-dialog-buttongroup {
  display: flex;
  gap: 8px;
  padding: 0;
  border: none;
}

.sec-data .buttons {
  display: flex;
  gap: 8px;
  margin: 0;
}

.k-actions
  .k-actions-horizontal
  .k-window-actions
  .k-dialog-actions
  .k-actions-stretched {
  display: flex;
  gap: 8px;
  margin: 0;
}

.sec-data .Nobtn {
  box-sizing: border-box;
  font-family: "Paragraph";
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  gap: 4px;
  width: 128px;
  height: 30px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 2e-6) 0%,
      rgba(0, 0, 0, 0.02) 100%
    ),
    #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.sec-data .Nobtn:hover {
  color: #424242;
  background: #e4e4e4;
  transition: all 0.3s ease-in-out;
}

.sec-data .Yesbtn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  gap: 4px;
  width: 128px;
  height: 30px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.04) 99.48%,
      rgba(0, 0, 0, 0.04) 99.49%
    ),
    #16bae7;
  border: 1px solid #16bae7;
  border-radius: 4px;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.sec-data .Yesbtn:hover {
  color: var(--inchcape-white);
  background: #049ec9;
  transition: all 0.3s ease-in-out;
}

.sec-data .Yesbtn:active {
  background: var(--inchcape-blue);
  color: var(--inchcape-white);
  border-color: var(--inchcape-blue);
}



.admin-seat .k-grid tbody > tr:not(.k-detail-row):hover,
.admin-seat .k-grid tbody > tr:not(.k-detail-row).k-hover,
.admin-seat .k-table-tbody .k-table-row:hover,
.admin-seat .k-table-list .k-table-row:hover,
.admin-seat .k-table-tbody .k-table-row.k-hover,
.admin-seat .k-table-list .k-table-row.k-hover {
  background: #fff;
}

@media screen and (max-width: 767.9px) {
  .sec-data div.k-dialog {
    width: 85vw;
    padding: 20px 27px;
    height:65%;
  }

  .sec-data .information {
    gap: 5%;
  }

  .sec-data .information svg {
    flex: 0 0 auto;
  }

  .sec-data .date-range {
    width: 90% !important;
  }

  .sec-data .date-range .k-daterangepicker {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .sec-data .k-daterangepicker .k-dateinput,
  .sec-data .area-type span.k-dropdownlist {
    width: auto;
  }
 .sec-data .area-type .k-multiselect {
    width: 90%;
    
}
  .dateRange1 label {
    padding-left: 0 !important;
  }

  .sec-data .area-type {
    display: grid;
    grid-template-columns: 2fr 4fr;
    gap: 8px;
    justify-content: space-between;
  }

  .sec-data .area-type .Room-type {
    margin-right: 0;
  }

  .sec-data .area-type.top {
    grid-template-columns: 3fr 4fr 1fr;
  }

  .sec-data .area-type svg {
    justify-self: end;
  }

  .sec-data .area-type label {
    width: auto;
    word-wrap: break-word;
  }

  .k-calendar {
    width: 85vw !important;
    transform: translateX(-25px);
    overflow-x: auto !important;
  }
  .sec-data .Nobtn {
    box-sizing: border-box;
    font-family: "Paragraph";
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 9px;
    gap: 4px;
    width: 118px;
    height: 30px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 2e-6) 0%,
        rgba(0, 0, 0, 0.02) 100%
      ),
      #f5f5f5;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
  
  .sec-data .Nobtn:hover {
    color: #424242;
    background: #e4e4e4;
    transition: all 0.3s ease-in-out;
  }
  
  .sec-data .Yesbtn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 9px;
    gap: 4px;
    width: 118px;
    height: 30px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.04) 99.48%,
        rgba(0, 0, 0, 0.04) 99.49%
      ),
      #16bae7;
    border: 1px solid #16bae7;
    border-radius: 4px;
    font-family: "Paragraph";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }
  
  .sec-data .Yesbtn:hover {
    color: var(--inchcape-white);
    background: #049ec9;
    transition: all 0.3s ease-in-out;
  }
  
  .sec-data .Yesbtn:active {
    background: var(--inchcape-blue);
    color: var(--inchcape-white);
    border-color: var(--inchcape-blue);
  }
}
