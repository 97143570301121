/* Size of panel of admin-home */
.admin-home {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.inp {
  align-self: flex-end;
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px 5px 0px;
  width: 237px;
  height: 30px;
  background: #ffffff;
  gap: 4px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.admin-home .k-table,
.admin-home .k-grid-table {
  width: 100% !important;
}

.inp > input {
  width: 204px;
  height: 20px;
  border: none;
  outline: none;
  font-family: "Paragraph";
  font-size: 14px;
  color: #666666;
}

.admin-home .k-grid-content {
  overflow-y: hidden;
}

.admin-home .k-column-title {
  min-width: 0;
  text-overflow: ellipsis;
  font-family: "Title";
  color: rgba(0, 35, 58, 0.6);
  text-align: center;
  white-space: normal;
  flex-shrink: 1;
  overflow: hidden;
}

.admin-home .k-grid-header {
  padding-right: 0;
}

.admin-home .k-grid-header-wrap {
  border: 0;
}
.admin-home .k-table-md .k-table-td, .k-table-md .k-table-th {
  padding: 0px;
}
.admin-home th.k-header label.k-checkbox-label {
  font-size: 14px;
  font-weight: 600px;
  font-family: "Title";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  /* Primary Inchcape/60% */
  color: rgba(0, 35, 58, 0.6);
  text-align: center;
}
.admin-home .k-grid td {
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  /* Primary Inchcape/90% */
  color: rgba(0, 35, 58, 0.9);
  background-color: #fff;
  border: none;
  text-overflow: initial;
  white-space: initial;
  word-break: break-word;
}
.admin-home .k-grid th {
  border-width: 0 0 1px 0 !important;
  text-align: center;
}
.del {
  height: 10px;
  width: 10px;
}
.admin-home .k-cell-inner {
  justify-content: center;
}
.admin-home .k-grid-content {
  overflow-y: hidden;
}
.admin-home .k-grid-header {
  padding-right: 0;
}
.admin-home .k-grid-header-wrap {
  border: 0;
}
.tbl {
  width: 100%;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
}
.admin-home .k-checkbox:checked,
.admin-home .k-checkbox:checked:focus,
.admin-home .k-checkbox.k-checked.k-focus {
  color: #fff !important;
  border-color: rgb(22, 186, 231) !important;
  background-color: #16bae7 !important;
  box-shadow: 0 0 0 2px rgb(22 186 231 / 30%) !important;
}
.tbl div.k-grid {
  margin-top: 0;
  border: none;
  width: 100%;
}
.history-rep {
  margin-top: 0 !important;
}

/* For style and position of checkbox header label */
.admin-home th.k-header label.k-checkbox-label {
  font-size: 14px;
  font-weight: 600px;
  margin: 0;
}

/* checkbox on select header */
.tbl th.k-header input.k-checkbox {
  display: none;
}

/* For style and position of checkbox */
.admin-home input.k-checkbox {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  margin: 12px 0 20px;
}

/* checkbox size */
.admin-home tr.k-master-row input.k-checkbox {
  width: 16px;
  height: 16px;
}

/* change row color if selected */
.admin-home .k-grid td.k-selected,
.admin-home .k-grid tr.k-selected > td {
  background-color: #16bae755;
}

.admin-home .k-pager-numbers .k-selected .k-button-text {
  color: var(--inchcape-blue) !important;
}
.admin-home select.k-dropdown {
  display: none !important;
}
.admin-home .k-table-thead {
  background-color: #fff;
}
.admin-home .k-button-flat-primary {
  color: rgba(0, 35, 58, 0.5) !important;
}

.admin-home .k-grid-pager {
  border: none !important;
}

.admin-home .k-grid th {
  text-overflow: initial;
  white-space: initial;
  word-break: break-all;
}

.admin-home .k-grid th:last-child {
  word-break: normal;
}

.admin-home .k-grid td:first-child {
  text-overflow: clip;
}

.admin-home.k-pager-wrap,
.admin-home .k-pager {
  border-color: rgba(0, 0, 0, 0.08);
  color: #212529;
  background-color: #ffffff;
}
.admin-home .k-pager-numbers {
  display: block;
}
.admin-home .k-pager-numbers .k-link:focus {
  box-shadow: none !important;
}
.admin-home .k-pager-numbers .k-link:hover {
  background-color: #fff !important;
  color: #16bae7;
}
.admin-home .k-pager-numbers .k-link {
  color: #424242;
}
.admin-home .history-module .k-pager-numbers .k-selected .k-button-text {
  color: var(--inchcape-blue) !important;
  /* background-color: #fff !important; */
}
.admin-home ul.pagination {
  height: 26px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.admin .k-notification-success {
  display: flex;
  gap: 4px;
  height: 27px;
  position: fixed;
  top: 8vh;
  font-family: "Sub Title";
  right: 5vw;
  border-color: #37b400;
  color: white;
  background-color: #37b400;
}
.admin-home .set-inp {
  display: flex;
  justify-content: space-between;
}
.admin-home .btn:disabled,
.admin-home .btn.disabled {
  background: #16bae7;
  border: 1px solid #16bae7;
  color: #fff;
}
.Set1 {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  width: auto;
  font-family: "Paragraph";
  min-width: 73px;
  gap: 4px;
  height: 30px;
  text-align: center;
  color: #ffffff;
  background: #16bae7;
  border: 1px solid #16bae7;
  border-radius: 4px;
  /* Inside auto layout */
  flex: none;
  order: -1;
}

.Set1:hover {
  background: #049ec9;
  color: #fff;
  border: 1px solid #16bae7;
}

.Set1:active {
  background: #16bae7 !important;
  color: #fff !important;
  border: 1px solid #16bae7 !important;
}

.admin-home .k-button-flat.k-selected {
  color: #16bae7 !important;
}

.admin-home .k-table-tbody .k-table-row.k-selected,
.admin-home .k-table-list .k-table-row.k-selected {
  background-color: transparent;
}

/* .admin-home td {
  min-width: 50px;
  text-overflow: ellipsis;
} */

@media screen and (max-width: 991.9px) {
  .admin-home .mobile-table {
    border: none;
    margin-bottom: 4rem;
  }

  .admin-home .mobile-table .top {
    border: 2px solid #16bae7;
  }

  .admin-home .mobile-table .bot {
    border: 1px solid #414141;
  }

  .admin-home .mobile-table .line {
    width: 105%;
    gap: 10px;
    grid-template-columns: minmax(1fr, 120px) 1fr;
  }
  .admin-home .mobile-table .k-checkbox-md{
    width: 30px;
    height: 30px;
    /* margin: 12px 0 20px; */
    display: flex;
    flex-direction: column;
    bottom: 10px;
    
    
 }
  .admin-home .mobile-table .top > div,
  .admin-home .mobile-table .bot > div {
    gap: 5px;
  }

  .admin-home .mobile-table label {
    font-family: "Title";
    color: rgba(0, 35, 58, 0.6);
    text-align: start;
    font-size: 14px;
  }

  .admin-home .mobile-table p {
    max-width: 100%;
    font-family: "Sub Title";
    font-size: 13px;
    word-break: break-all;
    color: rgba(0, 35, 58, 0.9);
    text-align: start;
  }
}

@media screen and (max-width: 767.9px) {
  .admin-home {
    width: 100%;
  }

  .admin-home .tbl {
    width: 100%;
  }

  .admin-home .set-inp {
    flex-direction: column;
    gap: 11px;
  }
  .admin-home .mobile-table .k-checkbox-md{
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: column;
    bottom: 10px;
    
    
 }
  .admin-home .inp {
    width: 100%;
    order: -1;
  }

  .admin-home .Set1 {
    order: -1;
    font-family: "Paragraph";
    align-self: flex-end;
  }
}



/* History */
.userconstraint {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 45px;
  background: #ffffff;
}

.userconstraint .contents {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  justify-items: center;
  column-gap: 45px;
  row-gap: 26px;
  border: 1px solid var(--inchcape-blue);
  margin-bottom: 30px;
  padding: min(1.7vw, 55px) min(3vw, 81px);
}

.userconstraint .offices {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  padding: 20px 24px;
  height: 110px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: inset 0 90px 20px 0 rgba(150, 150, 150, 0.2),
    inset 0 -25px 40px 2px #00233a;
}

.userconstraint .contents p {
  text-align: center;
  color: #ffffff;
  font-family: "Title";
  font-weight: 700;
  font-size: max(0.73vw, 16px);
  margin: 0;
  padding: 0;
}

.userconstraint .create {
  border: none;
}

.userconstraint .contents svg,
.userconstraint .create svg {
  cursor: pointer;
  width: 16px;
  height: 16px;
  color: #ffffff;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.userconstraint div.k-notification-success,
.userconstraint div.k-notification-error {
  position: fixed;
  display: flex;
  gap: 4px;
  top: 10vh;
  right: 5vw;
}

.userconstraint .k-animation-container-relative {
  position: fixed;
}

@media screen and (max-width: 767px) {
  .userconstraint .contents {
    display: flex;
    flex-direction: column;
    padding: 30px 33px;
  }

  .userconstraint .offices {
    height: 75px;
    align-items: center;
    box-shadow: inset 0 50px 10px 0 rgba(150, 150, 150, 0.2),
      inset 0 -20px 30px 2px #00233a;
  }

  .userconstraint .create svg,
  .userconstraint .contents svg {
    margin-bottom: 3px;
  }
}

/* start here */
.userconstraint .k-column-title {
  height: 34px;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  /* Primary Inchcape/60% */
  color: rgba(0, 35, 58, 0.6);
  min-width: 0;
  font-family: "Title";
  white-space: initial;
  flex-shrink: 1;
  overflow: hidden;
}
/* Style for label available seats */

.userHistory .k-grid-pager {
  font-family: "Paragraph";
}

/* For last div */
.userHistory {
  padding: 0px 0px 20px 0px;
  width: 100%;
}

.userHistory .k-grid-content {
  overflow-y: hidden;
}

.userHistory > div {
  width: 100%;
}
/* for table */
.uhistory {
  display: flex;
  padding: 40px 0px 0px 5px;
  width: 100%;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.uhistory .k-grid {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Pagination */
.userconstraint .k-grid-pager {
  /* flex-direction: row-reverse !important; */
  justify-content: center;
}

.userconstraint .k-pager-sizes {
  flex-direction: row-reverse !important;
  gap: 20px;
}
.userconstraint .k-pager-md .k-pager-info,
.k-pager-sm .k-pager-info,
.k-pager-sm .k-pager-sizes,
.k-pager-sm .k-pager-numbers {
  display: flex;
}
.userconstraint .k-pager-numbers .k-selected {
  color: #16bae7 !important;
}

.userconstraint .k-button-flat-primary {
  color: rgba(0, 35, 58, 0.5) !important;
}

.userconstraint .k-pager-sizes .k-dropdownlist {
  border: none !important;
  background-color: #ffffff !important;
}

.userconstraint .k-picker-solid:focus-within {
  box-shadow: none !important;
}

.userconstraint .k-pager-numbers .k-link.k-selected {
  background-color: #ffffff !important;
  color: #16bae7 !important;
  border: 1px #ffffff !important;
}

.userconstraint .k-pager-numbers .k-link:hover {
  background-color: #ffffff !important;
}

.userconstraint .k-pager-numbers .k-link:focus {
  box-shadow: none !important;
  color: #16bae7 !important;
}

.userconstraint .k-pager-numbers .k-link {
  color: rgba(0, 35, 58, 0.5) !important;
  cursor: pointer !important;
}

.userconstraint .k-pager-nav.k-link:focus {
  background-color: #ffffff !important;
  box-shadow: none !important;
}

.userconstraint .k-pager-nav.k-link:hover {
  background-color: rgba(22, 186, 231, 0.3) !important;
  box-shadow: none !important;
  border-radius: 50px;
}
.Add3 {
  color: #16bae7;
  width: 25px;
  height: 25px;
}

/* for history */
.userHistory-title {
  font-size: 20px;
  font-weight: 700;
  font-family: "Title";
  font-style: normal;
  border-bottom: 1px solid #00233a;
  padding-bottom: 10px;
  margin: 0;
}
.userconstraint .k-widget,
.userconstraint .k-grid {
  border: none !important;
  width: 100%;
}

.userconstraint .k-grid-header {
  font-family: "Title";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  padding: 0;
  color: rgba(0, 35, 58, 0.6) !important;
}

.userconstraint .k-grid tr {
  background: #fff;
  position: relative;
}

.userconstraint .k-header > .k-cell-inner {
  margin: 0px 0px !important;
}

.userconstraint .k-header,
.userconstraint .k-header {
  border: none !important;
  border-bottom: 1px solid #ebeff2 !important;
}

.userconstraint .k-cell-inner {
  justify-content: center !important;
  margin: 0;
  padding: 0;
}

.userconstraint .k-cell-inner > .k-link {
  padding: 0px !important;
}

.userconstraint .k-link {
  cursor: normal !important;
}

.userconstraint .k-master-row,
.userconstraint .k-alt {
  border-right: none !important;
}

.userconstraint .k-grid tbody {
  text-align: center !important;
}

.userconstraint .k-grid th {
  padding: 15px 6px !important;
}

.userconstraint .k-grid td {
  border: none !important;
  padding: 15px 22px !important;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  color: rgba(0, 35, 58, 0.9) !important;
  background-color: #fff;
  border: none;
}
.userconstraint .k-pager-numbers .k-link.k-selected .k-button-text {
  color: var(--inchcape-blue) !important;
}
.userconstraint select.k-dropdown {
  display: none !important;
}
.userconstraint .k-button-flat-primary {
  color: rgba(0, 35, 58, 0.5) !important;
  border: none !important;
}

.userconstraint .k-grid-pager {
  border: none !important;
}
.userconstraint .k-grid th {
  border-width: 0 0 1px 0 !important;
}
.userconstraint.k-pager-wrap,
.userconstraint .k-pager {
  border-color: rgba(0, 0, 0, 0.08);
  color: #212529;
  background-color: #ffffff;
}
.userconstraint .k-pager-numbers {
  display: block;
}
.userconstraint .k-grid-th {
  border-width: 0 0 1px 0;
}

/* Pagination */
.userconstraint .k-grid-pager {
  /* flex-direction: row-reverse !important; */
  justify-content: center;
}

.userconstraint .k-pager-sizes {
  flex-direction: row-reverse !important;
  gap: 20px;
}

.userconstraint .k-pager-numbers .k-selected {
  color: #16bae7 !important;
}

.userconstraint .k-button-flat-primary {
  color: rgba(0, 35, 58, 0.5) !important;
}

.userconstraint .k-pager-sizes .k-dropdownlist {
  border: none !important;
  background-color: #ffffff !important;
}

.userconstraint .k-picker-solid:focus-within {
  box-shadow: none !important;
}

.userconstraint .k-pager-numbers .k-link.k-selected {
  background-color: #ffffff !important;
  color: #16bae7 !important;
  border: 1px #ffffff !important;
}

.userconstraint .k-pager-numbers .k-link:hover {
  background-color: #ffffff !important;
}

.userconstraint .k-pager-numbers .k-link:focus {
  box-shadow: none !important;
  color: #16bae7 !important;
}

.userconstraint .k-pager-numbers .k-link {
  color: rgba(0, 35, 58, 0.5) !important;
  cursor: pointer !important;
}

.userconstraint .k-pager-nav.k-link:focus {
  background-color: #ffffff !important;
  box-shadow: none !important;
}
.userconstraint .k-notification-success {
  display: flex;
  gap: 4px;
  height: 27px;
  position: fixed;
  font-family: "Sub Title";
  top: 8vh;
  right: 5vw;
  border-color: #37b400;
  color: white;
  background-color: #37b400;
}
.userconstraint .k-pager-nav.k-link:hover {
  background-color: rgba(22, 186, 231, 0.3) !important;
  box-shadow: none !important;
  border-radius: 50px;
}

.userconstraint .k-table {
  width: 100% !important;
}

.userconstraint .k-grid th,
.userconstraint .k-grid td {
  text-overflow: initial;
  white-space: normal;
  word-break: break-word;
}

@media screen and (max-width: 991.9px) {
  .userconstraint .seatcapacity-title {
    padding-bottom: 0;
    font-size: min(7vw, 20px);
  }

  .floorlayout-btn {
    padding: 5px 8px;
  }

  .floorlayout-btn,
  .floorlayout-popout {
    height: auto;
    width: min(35vw, 186px);
    gap: 8px;
    flex-wrap: wrap;
  }

  .floorlayout-btn svg {
    width: 16px;
    height: 16px;
  }

  .floorlayout-btn > span {
    width: auto;
  }

  .userconstraint .row {
    padding: 0;
    margin: 0;
  }

  .userconstraint .row > *:not(.inputDate):not(.lblAvailableseats),
  .userconstraint .inputDate .k-daterangepicker label {
    padding: 0 5px !important;
  }

  .userconstraint .mobile-table .line {
    width: 105%;
    gap: 10px;
    grid-template-columns: minmax(1fr, 120px) 1fr;
  }

  .userconstraint .mobile-table .top > div,
  .userconstraint .mobile-table .bot > div {
    gap: 18px;
  }

  .userconstraint .mobile-table .line svg {
    color: #e40521;
  }

  .userconstraint .mobile-table .bot > div {
    padding-top: 0;
  }

  .userconstraint .mobile-table label {
    font-family: "Title";
    color: rgba(0, 35, 58, 0.6);
  }

  .userconstraint .mobile-table p {
    max-width: 100%;
    font-family: "Sub Title";
    font-size: 14px;
    color: rgba(0, 35, 58, 0.9);
    word-break: break-all;
  }
}


.view-button {
  /* _Base Button / Medium */
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  gap: 4px;
  width: 128px;
  height: 30px;
  font-family: "Paragraph";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: center;
  /* $component-text-white */
  color: #ffffff;
  /* $component-text-white */
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.04) 99.48%,
      rgba(0, 0, 0, 0.04) 99.49%
    ),
    #16bae7;
  /* Buttons/Primary/Border/$primary-button-border
 primary solid button - normal/focused border; slider handle - normal border; floating action button (FAB) - normal/focused border
 */

  border: 1px solid #16bae7;
  border-radius: 4px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  margin: auto;
}
.view-button:hover {
  color: var(--inchcape-white);
  background: #049ec9;
  transition: all 0.3s ease-in-out;
}
.view-button:disabled {
  cursor: default;
  opacity: 0.7;
  background-color: var(--inchcape-blue);
}
