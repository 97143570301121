.mobile-table {
  width: 100%;
  background: #ffffff;
  border: 1px solid #b8b8b8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.mobile-table .top,
.mobile-table .bot {
  display: flex;
  justify-content: space-between;
}

.mobile-table .top > div,
.mobile-table .bot > div {
  user-select: none;
  display: flex;
  max-width: 90%;
  flex-direction: column;
  padding: 15px;
  flex: 2;
}

.mobile-table .line {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.mobile-table .line > p,
.mobile-table .line > label {
  font-size: 13px;
  line-height: 20px;
  max-width: 100%;
  word-wrap: break-word;
  padding: 0;
  margin: 0;
}

.mobile-table .arrow {
  cursor: pointer;
  width: 21px;
  height: 21px;
  color: #414141;
  margin: 10px;
}

.mobile-table svg:not(.arrow) {
  width: 22px;
  height: 22px;
}
